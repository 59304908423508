import { generateSchema, generateUiSchema } from "utils/schemaUtils";
import { aladdinItems } from "./aladdin";
import { minotaurItems } from "./minotaur";
import { templeOfSkullItems } from "./templeOfSkull";
import { orphanageItems } from "./orphanage";
import { atlantisItems } from "./atlantis";
import { draculaItems } from "./dracula";
import { orientExpressItems } from "./orientExpress";
import { philosopherstone2_4_SECRET_KEEPERS } from "./fourSecretKeepers";
import { nightmareHotelItems } from "./nightmareHotel";
import { hauntedMansionItems } from "./hauntedMansion";
import { twdItems } from "./twd";
import { circusItems } from "./circus";
import { queenAnnesItems } from "./queenAnnes";
import { philosophersStoneItems } from "./philosophersStone";
import { illuminatiItems } from "./illuminati";
import { whiteHouseItems } from "./whiteHouse";
import { lmdpItems } from "./lmdp";
import { chernobylItems } from "./chernobyl";
import { topSecretItems } from "./topSecret";
import { topSecret2Items } from "./topSecret2";
import { chocolateItems } from "./chocolate";
import { maniacItems } from "./maniac";
import { jackTheRipperItems } from "./jackTheRipper";
import { toymakerItems } from "./toymaker";
import { twentyThousandLeaguesItems } from "./20kLeagues";
import { chocolate2Items } from "./chocolate2";
import { roomNames } from "utils/enums";

const roomItemsMap = {
    [roomNames.JACK_THE_RIPPER]: aladdinItems,
    [roomNames.ALADDIN]: aladdinItems,
    [roomNames.MINOTAUR]: minotaurItems,
    [roomNames.MINOTAURS_LABYRINTH]: minotaurItems,
    [roomNames.TEMPLE_OF_THE_SKULL]: templeOfSkullItems,
    [roomNames.ORPHANAGE]: orphanageItems,
    [roomNames.ATLANTIS]: atlantisItems,
    [roomNames.LOST_CITY_OF_ATLANTIS]: atlantisItems,
    [roomNames.DRACULA]: draculaItems,
    [roomNames.ORIENT_EXPRESS]: orientExpressItems,
    [roomNames.ORIENT_EXPRESS_THE_MURDER]: orientExpressItems,
    [roomNames.PHILOSOPHERS_STONE_4_SECRET_KEEPERS]:
        philosopherstone2_4_SECRET_KEEPERS,
    [roomNames.NIGHTMARE_HOTEL]: nightmareHotelItems,
    [roomNames.HAUNTED_MANSION]: hauntedMansionItems,
    [roomNames.WALKING_DEATH]: twdItems,
    [roomNames.CIRCUS]: circusItems,
    [roomNames.HORROR_CIRCUS]: circusItems,
    [roomNames.PIRATES_QUEEN_ANNES_REVENGE]: queenAnnesItems,
    [roomNames.PHILOSOPHERS_STONE]: philosophersStoneItems,
    [roomNames.ILLUMINATI]: illuminatiItems,
    [roomNames.WHITE_HOUSE]: whiteHouseItems,
    [roomNames.LA_CASA]: lmdpItems,
    [roomNames.LA_MISSION_DEL_PROFESSOR]: lmdpItems,
    [roomNames.CHERNOBYL]: chernobylItems,
    [roomNames.TOP_SECRET]: topSecretItems,
    [roomNames.TOP_SECRET_2]: topSecret2Items,
    [roomNames.CHOCOLATE_FACTORY]: chocolateItems,
    [roomNames.MANIAC_I_WANT_TO_PLAY_A_GAME]: maniacItems,
    [roomNames.JACK_THE_RIPPER]: jackTheRipperItems,
    [roomNames.TOYMAKER]: toymakerItems,
    [roomNames.TWENTY_THOUSAND_LEAGUES]: twentyThousandLeaguesItems,
    [roomNames.CHOCOLATE_FACTORY_2]: chocolate2Items,
};

export const UiSchemaMap = () =>
    new Map(
        Object.entries(roomItemsMap).map(([room, items]) => [
            room,
            generateUiSchema(items),
        ])
    );

export const JsSchemaMap = () =>
    new Map(
        Object.entries(roomItemsMap).map(([room, items]) => [
            room,
            generateSchema(items),
        ])
    );
