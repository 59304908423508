import { useState } from "react";
import PropTypes from "prop-types";
import "./style.css";

const Login = ({ handleLogin, handleLogout, loginProfile }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "username") {
            setUsername(value);
        } else if (name === "password") {
            setPassword(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = { username, password };
        handleLogin(data);
    };

    return (
        <>
            {loginProfile ? (
                <div className='logOutContainer'>
                    <h1>Hello {loginProfile}!</h1>
                    <button
                        className='logOutButton'
                        onClick={handleLogout}>
                        Log out
                    </button>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <input
                        autoComplete='true'
                        type='text'
                        name='username'
                        placeholder='Username'
                        value={username}
                        onChange={handleChange}
                        required
                    />

                    <input
                        autoComplete='true'
                        type='password'
                        name='password'
                        placeholder='Password'
                        value={password}
                        onChange={handleChange}
                        required
                    />

                    <button
                        type='submit'
                        className='login'>
                        Login
                    </button>
                </form>
            )}
        </>
    );
};

Login.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    loginProfile: PropTypes.string,
};

export default Login;
