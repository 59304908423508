import { roomNames as r, countries as c } from "./enums";

const store = [
    {
        name: "Oftersheim",
        value: "Oftersheim",
        country: c.GERMANY,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.PIRATES_OF_THE_CARIBBEAN,
            r.CHOCOLATE_FACTORY,
            r.HUNTERS_LODGE,
            r.LA_MISSION_DEL_PROFESSOR,
        ],
    },
    {
        name: "Heusden",
        value: "Heusden",
        country: c.BELGIUM,
        rooms: [
            r.CIRCUS,
            r.DRACULA,
            r.ALICE,
            r.LA_MISSION_DEL_PROFESSOR,
            r.PHILOSOPHERS_STONE,
            r.HAUNTED_MANSION,
        ],
    },
    {
        name: "Neu Ulm",
        value: "Neu Ulm",
        country: c.GERMANY,
        rooms: [
            r.PHANTAZMA_MEDIEVAL_CASTLE,
            r.LOST_CITY_OF_ATLANTIS,
            r.CHOCOLATE_FACTORY,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.HUNTERS_LODGE,
        ],
    },
    {
        name: "Mannheim",
        value: "Mannheim",
        country: c.GERMANY,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.PHANTAZMA_MEDIEVAL_CASTLE,
            r.LOST_CITY_OF_ATLANTIS,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.REACTOR,
            r.JACK_THE_RIPPER,
            r.PHILOSOPHERS_STONE,
            r.HAUNTED_MANSION,
            r.ILLUMINATI,
        ],
    },
    {
        name: "Denzlingen",
        value: "Denzlingen",
        country: c.GERMANY,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.TUTANKHAMUNS_TOMB,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.HUNTERS_LODGE,
            r.TOP_SECRET,
            r.PHILOSOPHERS_STONE,
        ],
    },
    {
        name: "Kornwestheim",
        value: "Kornwestheim",
        country: c.GERMANY,
        rooms: [r.FORBIDDEN_TEMPLE_OF_MONTEZUMA, r.TUTANKHAMUNS_TOMB],
    },
    {
        name: "Karlsruhe",
        value: "Karlsruhe",
        country: c.GERMANY,
        rooms: [
            r.PIRATES_OF_THE_CARIBBEAN,
            r.JACK_THE_RIPPER,
            r.WINTER_IS_COMING,
        ],
    },
    {
        name: "Saarbruecken",
        value: "Saarbruecken",
        country: c.GERMANY,
        rooms: [
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.TUTANKHAMUNS_TOMB,
            r.WINTER_IS_COMING,
            r.JACK_THE_RIPPER,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Merzig",
        value: "Merzig",
        country: c.GERMANY,
        rooms: [r.CHOCOLATE_FACTORY],
    },
    {
        name: "Bremen",
        value: "Bremen",
        country: c.GERMANY,
        rooms: [
            r.PIRATES_OF_THE_CARIBBEAN,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
        ],
    },
    {
        name: "Gelsenhirchen",
        value: "Gelsenhirchen",
        country: c.GERMANY,
        rooms: [r.MANIAC_I_WANT_TO_PLAY_A_GAME, r.NIGHTMARE_HOTEL],
    },
    {
        name: "Grundau",
        value: "Grundau",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.WALKING_DEATH],
    },
    {
        name: "Giessen",
        value: "Giessen",
        country: c.GERMANY,
        rooms: [r.CHOCOLATE_FACTORY, r.WINTER_IS_COMING, r.JACK_THE_RIPPER],
    },
    {
        name: "Aschaffenburg",
        value: "Aschaffenburg",
        country: c.GERMANY,
        rooms: [r.PIRATES_OF_THE_CARIBBEAN, r.WINTER_IS_COMING],
    },
    {
        name: "Frechen",
        value: "Frechen",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.WINTER_IS_COMING],
    },
    {
        name: "Memmingen",
        value: "Memmingen",
        country: c.GERMANY,
        rooms: [r.REACTOR],
    },
    {
        name: "Bochum",
        value: "Bochum",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB],
    },
    {
        name: "Goppingen",
        value: "Goppingen",
        country: c.GERMANY,
        rooms: [r.REACTOR],
    },
    {
        name: "Augsburg",
        value: "Augsburg",
        country: c.GERMANY,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.PIRATES_OF_THE_CARIBBEAN,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
        ],
    },
    {
        name: "Ingolstadt",
        value: "Ingolstadt",
        country: c.GERMANY,
        rooms: [r.FORBIDDEN_TEMPLE_OF_MONTEZUMA, r.REACTOR, r.TOP_SECRET],
    },
    {
        name: "Magdeburg",
        value: "Magdeburg",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.MINOTAURS_LABYRINTH, r.JACK_THE_RIPPER],
    },
    {
        name: "Darmstadt",
        value: "Darmstadt",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.TOP_SECRET],
    },
    {
        name: "Nurnberg",
        value: "Nurnberg",
        country: c.GERMANY,
        rooms: [
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Bensheim",
        value: "Bensheim",
        country: c.GERMANY,
        rooms: [
            r.TUTANKHAMUNS_TOMB,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
            r.SPACE_WARS,
            r.WALKING_DEATH,
            r.AZTEC_EMPIRE_TEMPLE_OF_THE_SKULL,
            r.ARKHAM_PRISON,
            r.ORDER_OF_ASSASSINS,
        ],
    },
    {
        name: "Wuppertal",
        value: "Wuppertal",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.JACK_THE_RIPPER],
    },
    {
        name: "Rostock",
        value: "Rostock",
        country: c.GERMANY,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.PIRATES_OF_THE_CARIBBEAN,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
            r.REACTOR,
            r.JACK_THE_RIPPER,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Wolfsburg",
        value: "Wolfsburg",
        country: c.GERMANY,
        rooms: [
            r.PIRATES_OF_THE_CARIBBEAN,
            r.TUTANKHAMUNS_TOMB,
            r.WALKING_DEATH,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Hildesheim",
        value: "Hildesheim",
        country: c.GERMANY,
        rooms: [r.JACK_THE_RIPPER],
    },
    {
        name: "Hannover",
        value: "Hannover",
        country: c.GERMANY,
        rooms: [r.MINOTAURS_LABYRINTH, r.PHILOSOPHERS_STONE],
    },
    {
        name: "Hamm",
        value: "Hamm",
        country: c.GERMANY,
        rooms: [
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.PIRATES_OF_THE_CARIBBEAN,
            r.WINTER_IS_COMING,
            r.SPACE_WARS,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
            r.ORDER_OF_ASSASSINS,
        ],
    },
    {
        name: "Berlin",
        value: "Berlin",
        country: c.GERMANY,
        rooms: [r.TUTANKHAMUNS_TOMB, r.JACK_THE_RIPPER, r.WINTER_IS_COMING],
    },
    {
        name: "Grunstadt",
        value: "Grunstadt",
        country: c.GERMANY,
        rooms: [r.TOP_SECRET],
    },
    {
        name: "Hannover",
        value: "Hannover",
        country: c.GERMANY,
        rooms: [r.JACK_THE_RIPPER],
    },
    {
        name: "Landau",
        value: "Landau",
        country: c.GERMANY,
        rooms: [
            r.PHILOSOPHERS_STONE,

            r.ILLUMINATI,
            r.HORROR_CIRCUS,
            r.TOP_SECRET,
            r.LOST_CITY_OF_ATLANTIS,
        ],
    },
    {
        name: "Gottingen",
        value: "Gottingen",
        country: c.GERMANY,
        rooms: [r.SANITARIUM],
    },
    {
        name: "Landshut",
        value: "Landshut",
        country: c.GERMANY,
        rooms: [r.REACTOR, r.JACK_THE_RIPPER, r.WINTER_IS_COMING],
    },
    {
        name: "Rosenheim",
        value: "Rosenheim",
        country: c.GERMANY,
        rooms: [
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.PHILOSOPHERS_STONE,
            r.HUNTERS_LODGE,
        ],
    },
    {
        name: "Bonn",
        value: "Bonn",
        country: c.GERMANY,
        rooms: [r.PHILOSOPHERS_STONE],
    },
    {
        name: "Trier",
        value: "Trier",
        country: c.GERMANY,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.TUTANKHAMUNS_TOMB,
            r.REACTOR,
            r.JACK_THE_RIPPER,
            r.HUNTERS_LODGE,
            r.HAUNTED_MANSION,
            r.PHILOSOPHERS_STONE,
        ],
    },
    {
        name: "Kaiserslautern",
        value: "Kaiserslautern",
        country: c.GERMANY,
        rooms: [
            r.TUTANKHAMUNS_TOMB,
            r.REACTOR,
            r.JACK_THE_RIPPER,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.SPACE_WARS,
        ],
    },
    {
        name: "Rome GO",
        value: "Rome GO",
        country: c.ITALY,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.LA_MISSION_DEL_PROFESSOR,
            r.JACK_THE_RIPPER,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.SPACE_WARS,
            r.ILLUMINATI,
            r.LOST_CITY_OF_ATLANTIS,
            r.REACTOR,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
            r.PHILOSOPHERS_STONE_2,
            r.HAUNTED_MANSION,
            r.HORROR_CIRCUS,
            r.WHITE_HOUSE,
            r.ASYLUM,
            r.ALADDIN,
            r.ORFANOTROFIO,
            r.DRACULA,
            r.ORIENT_EXPRESS,
        ],
    },
    {
        name: "Torino GO",
        value: "Torino GO",
        country: c.ITALY,
        rooms: [
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.TUTANKHAMUNS_TOMB,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Florence GO",
        value: "Florence GO",
        country: c.ITALY,
        rooms: [
            r.CHOCOLATE_FACTORY,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.SPACE_WARS,
            r.WALKING_DEATH,
        ],
    },
    {
        name: "Verona GO",
        value: "Verona GO",
        country: c.ITALY,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.WALKING_DEATH,
            r.DRACULA,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
        ],
    },
    {
        name: "Lecce GO",
        value: "Lecce GO",
        country: c.ITALY,
        rooms: [r.PHILOSOPHERS_STONE, r.ATLANTIS, r.HAUNTED_MANSION],
    },
    {
        name: "Kolding",
        value: "Kolding",
        country: c.DENMARK,
        rooms: [
            r.CHOCOLATE_FACTORY,
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.HUNTERS_LODGE,
        ],
    },
    {
        name: "Skive",
        value: "Skive",
        country: c.DENMARK,
        rooms: [r.HUNTERS_LODGE],
    },
    {
        name: "Haderslev",
        value: "Haderslev",
        country: c.DENMARK,
        rooms: [r.HUNTERS_LODGE],
    },
    {
        name: "Odense",
        value: "Odense",
        country: c.DENMARK,
        rooms: [
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.JACK_THE_RIPPER,
            r.HUNTERS_LODGE,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Vejle",
        value: "Vejle",
        country: c.DENMARK,
        rooms: [r.PHANTAZMA_MEDIEVAL_CASTLE, r.HUNTERS_LODGE],
    },
    {
        name: "Naestved",
        value: "Naestved",
        country: c.DENMARK,
        rooms: [r.FORBIDDEN_TEMPLE_OF_MONTEZUMA, r.HUNTERS_LODGE, r.TOP_SECRET],
    },
    {
        name: "Viborg",
        value: "Viborg",
        country: c.DENMARK,
        rooms: [
            r.PHANTAZMA_MEDIEVAL_CASTLE,
            r.HUNTERS_LODGE,
            r.TUTANKHAMUNS_TOMB,
        ],
    },
    {
        name: "Soenderborg",
        value: "Soenderborg",
        country: c.DENMARK,
        rooms: [r.JACK_THE_RIPPER, r.HUNTERS_LODGE, r.PHILOSOPHERS_STONE],
    },
    {
        name: "Aalborg",
        value: "Aalborg",
        country: c.DENMARK,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.PIRATES_QUEEN_ANNES_REVENGE,
            r.REACTOR,
            r.WALKING_DEATH,
            r.HAUNTED_MANSION,
        ],
    },
    {
        name: "Lutterbach",
        value: "Lutterbach",
        country: c.SWITZERLAND,
        rooms: [r.MINOTAURS_LABYRINTH, r.ATLANTIS, r.ORPHANAGE],
    },
    {
        name: "Dietikon",
        value: "Dietikon",
        country: c.SWITZERLAND,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.LA_MISSION_DEL_PROFESSOR,
            r.JACK_THE_RIPPER,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
            r.PHILOSOPHERS_STONE,
            r.ORIENT_EXPRESS,
            r.TOYMAKER,
        ],
    },
    {
        name: "Geneva GO",
        value: "Geneva GO",
        country: c.SWITZERLAND,
        rooms: [r.JACK_THE_RIPPER, r.WINTER_IS_COMING, r.PHILOSOPHERS_STONE],
    },
    {
        name: "Obfelden",
        value: "Obfelden",
        country: c.SWITZERLAND,
        rooms: [
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.TUTANKHAMUNS_TOMB,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.REACTOR,
        ],
    },
    {
        name: "Rapperswil",
        value: "Rapperswil",
        country: c.SWITZERLAND,
        rooms: [r.PRISONERS_OF_WAR, r.LOST_CITY_OF_ATLANTIS],
    },
    {
        name: "Rumlang",
        value: "Rumlang",
        country: c.SWITZERLAND,
        rooms: [
            r.ALICE_IN_WONDERLAND,
            r.DRACULA,
            r.ILLUMINATI,
            r.HORROR_CIRCUS,
            r.ONE_THOUSAND_AND_ONE_NIGHTS,
            r.WALKING_DEATH,
            r.WHITE_HOUSE,
        ],
    },
    {
        name: "Schoenenwerd",
        value: "Schoenenwerd",
        country: c.SWITZERLAND,
        rooms: [r.WALKING_DEATH],
    },
    {
        name: "Schoftland",
        value: "Schoftland",
        country: c.SWITZERLAND,
        rooms: [
            r.CHOCOLATE_FACTORY,
            r.HAUNTED_MANSION,
            r.PHANTAZMA_MEDIEVAL_CASTLE,
            r.PIRATES_OF_THE_CARIBBEAN,
            r.TOP_SECRET,
        ],
    },
    {
        name: "London",
        value: "London",
        country: c.UK,
        rooms: [r.ATLANTIS, r.ALICE, r.NIGHTMARE_HOTEL, r.PHILOSOPHERS_STONE],
    },
    {
        name: "Derby",
        value: "Derby",
        country: c.UK,
        rooms: [
            r.JACK_THE_RIPPER,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
            r.WALKING_DEATH,
            r.MONTEZUMA,
        ],
    },
    {
        name: "Edinburgh",
        value: "Edinburgh",
        country: c.UK,
        rooms: [r.TOP_SECRET, r.PHILOSOPHERS_STONE],
    },
    {
        name: "Falkirk",
        value: "Falkirk",
        country: c.UK,
        rooms: [r.WALKING_DEATH],
    },
    {
        name: "Liverpool GO",
        value: "Liverpool GO",
        country: c.UK,
        rooms: [
            r.SQUID_GAME,
            r.LA_MISSION_DEL_PROFESSOR,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.TOP_SECRET,
            r.PIRATES_OF_THE_CARIBBEAN,
        ],
    },
    {
        name: "Lisbon GO",
        value: "Lisbon GO",
        country: c.PORTUGAL,
        rooms: [
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.JACK_THE_RIPPER,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
            r.WALKING_DEATH,
            r.LA_MISSION_DEL_PROFESSOR,
            r.PHILOSOPHERS_STONE,
            r.ILLUMINATI,
            r.HORROR_CIRCUS,
            r.HAUNTED_MANSION,
            r.ALADDIN,
            r.ORIENT_EXPRESS,
            r.NIGHTMARE_HOTEL,
            r.ATLANTIS,
            r.TOYMAKER,
        ],
    },
    {
        name: "Porto GO",
        value: "Porto GO",
        country: c.PORTUGAL,
        rooms: [
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
            r.TUTANKHAMUNS_TOMB,
        ],
    },
    {
        name: "Chermside",
        value: "Chermside",
        country: c.AUSTRALIA,
        rooms: [r.MANIAC_I_WANT_TO_PLAY_A_GAME, r.REACTOR, r.TOP_SECRET],
    },
    {
        name: "East Lands",
        value: "East Lands",
        country: c.AUSTRALIA,
        rooms: [r.PIRATES_OF_THE_CARIBBEAN, r.TUTANKHAMUNS_TOMB],
    },
    {
        name: "North Strathfield",
        value: "North Strathfield",
        country: c.AUSTRALIA,
        rooms: [r.HUNTERS_LODGE, r.CHOCOLATE_FACTORY],
    },
    {
        name: "Macarthur",
        value: "Macarthur",
        country: c.AUSTRALIA,
        rooms: [r.MINOTAURS_LABYRINTH, r.PHANTAZMA_MEDIEVAL_CASTLE],
    },
    {
        name: "Canberra",
        value: "Canberra",
        country: c.AUSTRALIA,
        rooms: [r.LOST_CITY_OF_ATLANTIS, r.PIRATES_QUEEN_ANNES_REVENGE],
    },
    {
        name: "Amsterdam GO",
        value: "Amsterdam GO",
        country: c.NETHERLANDS,
        rooms: [
            r.FORBIDDEN_TEMPLE_OF_MONTEZUMA,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.TOP_SECRET,
            r.WALKING_DEATH,
            r.NECROMANCY,
            r.LA_MISSION_DEL_PROFESSOR,
        ],
    },
    {
        name: "Marseille GO",
        value: "Marseille GO",
        country: c.FRANCE,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.LA_MISSION_DEL_PROFESSOR,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.ARKHAM_PRISON,
            r.WINTER_IS_COMING,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
            r.HAUNTED_MANSION,
            r.MONTEZUMA,
            r.ORIENT_EXPRESS,
        ],
    },
    {
        name: "Plan de Campagne GO",
        value: "Plan de Campagne GO",
        country: c.FRANCE,
        rooms: [
            r.PIRATES_OF_THE_CARIBBEAN,
            r.CHOCOLATE_FACTORY,
            r.JACK_THE_RIPPER,
            r.TUTANKHAMUNS_TOMB,
            r.SPACE_WARS,
            r.REACTOR,
            r.TOP_SECRET,
            r.THE_ORDER_OF_ASSASSINS,
            r.ALICE,
            r.DRACULA,
            r.CIRCUS,
            r.ILLUMINATI,
            r.ALADDIN,
            r.WHITE_HOUSE,
            r.NIGHTMARE_HOTEL,
            r.ORPHANAGE,
            r.MINOTAUR,
            r.SOM2,
        ],
    },
    {
        name: "Quimper",
        value: "Quimper",
        country: c.FRANCE,
        rooms: [
            r.TEMPLE_OF_THE_SKULL,
            r.CHOCOLATE_FACTORY,
            r.PHILOSOPHERS_STONE,
        ],
    },
    {
        name: "Lyon GO",
        value: "Lyon GO",
        country: c.FRANCE,
        rooms: [r.WINTER_IS_COMING, r.JACK_THE_RIPPER, r.TOP_SECRET],
    },
    {
        name: "Nantes GO",
        value: "Nantes GO",
        country: c.FRANCE,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.WALKING_DEATH,
            r.ILLUMINATI,
            r.LA_MISSION_DEL_PROFESSOR,
        ],
    },
    {
        name: "Perugia GO",
        value: "Perugia GO",
        country: c.ITALY,
        rooms: [
            r.ILLUMINATI,
            r.ORIENT_EXPRESS,
            r.HAUNTED_MANSION,
            r.MANIAC,
            r.TOP_SECRET,
            r.JACK_THE_RIPPER,
            r.PHILOSOPHERS_STONE,
            r.DRACULA,
        ],
    },
    {
        name: "Nancy GO",
        value: "Nancy GO",
        country: c.FRANCE,
        rooms: [
            r.PIRATES_OF_THE_CARIBBEAN,
            r.CHOCOLATE_FACTORY,
            r.JACK_THE_RIPPER,
            r.WINTER_IS_COMING,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Montpellier GO",
        value: "Montpellier GO",
        country: c.FRANCE,
        rooms: [
            r.LA_CASA,
            r.LOST_CITY_OF_ATLANTIS,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
            r.REACTOR,
            r.ILLUMINATI,
        ],
    },
    {
        name: "Warsaw GO",
        value: "Warsaw GO",
        country: c.POLAND,
        rooms: [
            r.MINOTAURS_LABYRINTH,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.JACK_THE_RIPPER,
            r.SPACE_WARS,
        ],
    },
    {
        name: "Kuwait City",
        value: "Kuwait City",
        country: c.KUWAIT,
        rooms: [
            r.CHOCOLATE_FACTORY,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
            r.SPACE_WARS,
            r.HAUNTED_MANSION,
            r.ATLANTIS,
        ],
    },
    {
        name: "Al Hamra",
        value: "Al Hamra",
        country: c.KUWAIT,
        rooms: [
            r.LA_MISSION_DEL_PROFESSOR,
            r.CIRCUS,
            r.PHILOSOPHERS_STONE_4_SECRET_KEEPERS,
            r.WALKING_DEATH,
        ],
    },
    {
        name: "Riyadh GO",
        value: "Riyadh GO",
        country: c.SAUDI_ARABIA,
        rooms: [r.WALKING_DEATH, r.PHILOSOPHERS_STONE, r.JACK_THE_RIPPER],
    },
    {
        name: "Jeddah GO",
        value: "Jeddah GO",
        country: c.SAUDI_ARABIA,
        rooms: [
            r.LA_MISSION_DEL_PROFESSOR,
            r.JACK_THE_RIPPER,
            r.LOST_CITY_OF_ATLANTIS,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
            r.ALICE,
            r.ALADDIN,
            r.WHITE_HOUSE,
            r.MANIAC,
            r.CIRCUS,
            r.DRACULA,
            r.NIGHTMARE_HOTEL,
            r.ORPHANAGE,
            r.PHILOSOPHERS_STONE_2,
            r.PIRATES,
            r.ORIENT_EXPRESS,
            r.TEMPLE_OF_THE_SKULL,
            r.MINOTAUR,
            r.HAUNTED_MANSION,
            r.ILLUMINATI,
        ],
    },
    {
        name: "Tampa FL GO",
        value: "Tampa FL GO",
        country: c.USA,
        rooms: [
            r.LA_MISSION_DEL_PROFESSOR,
            r.HAUNTED_MANSION,
            r.LOST_CITY_OF_ATLANTIS,
            r.PHILOSOPHERS_STONE,
            r.ALADDIN,
            r.DRACULA,
            r.TOP_SECRET,
            r.CIRCUS,
        ],
    },
    {
        name: "Atlanta GO",
        value: "Atlanta GO",
        country: c.USA,
        rooms: [
            r.ATLANTIS,
            r.PHILOSOPHERS_STONE,
            r.ALADDIN,
            r.TEMPLE_OF_THE_SKULL,
            r.CIRCUS,
        ],
    },
    {
        name: "PLANT CITY FL GO",
        value: "PLANT CITY FL GO",
        country: c.USA,
        rooms: [r.JACK_THE_RIPPER, r.MINOTAUR, r.ILLUMINATI, r.WALKING_DEATH],
    },
    {
        name: "ORLANDO GO",
        value: "ORLANDO GO",
        country: c.USA,
        rooms: [
            r.NIGHTMARE_HOTEL,
            r.PHILOSOPHERS_STONE_2,
            r.TEMPLE_OF_THE_SKULL,
            r.PIRATES,
            r.ORIENT_EXPRESS,
            r.ORPHANAGE,
            r.ALICE,
            r.ATLANTIS,
        ],
    },
    {
        name: "Aigaleo GO",
        value: "Aigaleo GO",
        country: c.GREECE,
        rooms: [r.JACK_THE_RIPPER, r.NECROMANCY],
    },
    // {
    //   name: "Nea Smirni GO",
    //   value: "Nea Smirni GO",
    //   country: c.GREECE,
    //   rooms: [
    //     r.LA_MISSION_DEL_PROFESSOR,
    //     r.AZTEC_EMPIRE_TEMPLE_OF_THE_SKULL,
    //
    //
    //   ],
    // },
    {
        name: "Queenstown",
        value: "Queenstown",
        country: c.NEW_ZEALAND,
        rooms: [r.MANIAC_I_WANT_TO_PLAY_A_GAME, r.MINOTAURS_LABYRINTH],
    },
    {
        name: "Ljubljana GO",
        value: "Ljubljana GO",
        country: c.SLOVENIA,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.HAUNTED_MANSION,
            r.PIRATES_QUEEN_ANNES_REVENGE,
            r.JACK_THE_RIPPER,
            r.CHOCOLATE_FACTORY,
            r.LA_MISSION_DEL_PROFESSOR,
            r.ILLUMINATI,
            r.HORROR_CIRCUS,
            r.WALKING_DEATH,
            r.PHILOSOPHERS_STONE,
        ],
    },
    {
        name: "Maribor GO",
        value: "Maribor GO",
        country: c.SLOVENIA,
        rooms: [r.ORIENT_EXPRESS, r.CHERNOBYL, r.WHITE_HOUSE, r.MANIAC],
    },
    {
        name: "Murska Sobota GO",
        value: "Murska Sobota GO",
        country: c.SLOVENIA,
        rooms: [r.ALADDIN, r.ALICE, r.DRACULA],
    },
    {
        name: "Pristina GO",
        value: "Pristina GO",
        country: c.KOSOVO,
        rooms: [
            r.HAUNTED_MANSION,
            r.ALADDIN,
            r.LA_MISSION_DEL_PROFESSOR,
            r.PHILOSOPHERS_STONE,
            r.CIRCUS,
            r.ATLANTIS,
            r.ORIENT_EXPRESS,
            r.WALKING_DEATH,
            r.NIGHTMARE_HOTEL,
            r.ORPHANAGE,
        ],
    },
    {
        name: "Gothenburg GO",
        value: "Gothenburg GO",
        country: c.SWEDEN,
        rooms: [
            r.MANIAC,
            r.LOST_CITY_OF_ATLANTIS,
            r.HAUNTED_MANSION,
            r.WALKING_DEATH,
        ],
    },
    {
        name: "DUBAI",
        value: "DUBAI",
        country: c.UAE,
        rooms: [
            r.ORIENT_EXPRESS_THE_MURDER,
            r.ONE_THOUSAND_AND_ONE_NIGHTS_ALF_LAYLA_WA_LAYLAH,
            r.ALICE_IN_WONDERLAND,
            r.HORROR_CIRCUS,
            r.HAUNTED_MANSION,
            r.MONEY_HEIST,
            r.LOST_CITY_OF_ATLANTIS,
            r.PHILOSOPHERS_STONE,
            r.WALKING_DEATH,
            r.DRACULA,
            r.ILLUMINATI,
            r.MANIAC_I_WANT_TO_PLAY_A_GAME,
        ],
    },
    {
        name: "CLEARWATER",
        value: "CLEARWATER",
        country: c.USA,
        rooms: [
            r.ORIENT_EXPRESS_THE_MURDER,
            r.NIGHTMARE_HOTEL,
            r.TEMPLE_OF_THE_SKULL,
            r.ALICE_IN_WONDERLAND,
        ],
    },
    {
        name: "WILMINGTON",
        value: "WILMINGTON",
        country: c.USA,
        rooms: [r.PHILOSOPHERS_STONE, r.ILLUMINATI, r.ATLANTIS, r.CIRCUS],
    },
    {
        name: "KATY TEXAS",
        value: "KATY TEXAS",
        country: c.USA,
        rooms: [
            r.ATLANTIS,
            r.PHILOSOPHERS_STONE_4_SECRET_KEEPERS,
            r.TEMPLE_OF_THE_SKULL,
            r.TOP_SECRET_2,
            r.NIGHTMARE_HOTEL,
        ],
    },
    {
        name: "ABU DHABI",
        value: "ABU DHABI",
        country: c.UAE,
        rooms: [
            r.ALICE_IN_WONDERLAND,
            r.ONE_THOUSAND_AND_ONE_NIGHTS_ALF_LAYLA_WA_LAYLAH,
            r.PIRATES,
            r.NIGHTMARE_HOTEL,
            r.LOST_CITY_OF_ATLANTIS,
            r.PHILOSOPHERS_STONE_2,
            r.ORPHANAGE,
            r.MINOTAUR,
            r.TEMPLE_OF_THE_SKULL,
        ],
    },
    {
        name: "TORONTO",
        value: "TORONTO",
        country: c.CANADA,
        rooms: [
            r.LOST_CITY_OF_ATLANTIS,
            r.PHILOSOPHERS_STONE,
            r.ALADDIN,
            r.ORIENT_EXPRESS,
            r.HORROR_CIRCUS,
            r.HAUNTED_MANSION,
            r.TEMPLE_OF_THE_SKULL,
        ],
    },
    {
        name: "CALGARY",
        value: "CALGARY",
        country: c.CANADA,
        rooms: [
            r.PHILOSOPHERS_STONE,
            r.ATLANTIS,
            r.HAUNTED_MANSION,
            r.TEMPLE_OF_THE_SKULL,
        ],
    },
    {
        name: "Bucharest",
        value: "Bucharest",
        country: c.ROMANIA,
        rooms: [r.LOST_CITY_OF_ATLANTIS, r.PHILOSOPHERS_STONE, r.WALKING_DEATH],
    },
    {
        name: "Malaga",
        value: "Malaga",
        country: c.SPAIN,
        rooms: [
            r.HAUNTED_MANSION,
            r.CHERNOBYL,
            r.ORIENT_EXPRESS,
            r.LA_MISSION_DEL_PROFESSOR,
        ],
    },
    {
        name: "Svendborg",
        value: "Svendborg",
        country: c.DENMARK,
        rooms: [
            r.NIGHTMARE_HOTEL,
            r.ILLUMINATI,
            r.TUTANKHAMUNS_TOMB,
            r.HUNTERS_LODGE,
        ],
    },
    {
        name: "Abidjan",
        value: "Abidjan",
        country: c.IVORY_COAST,
        rooms: [r.ALICE, r.ATLANTIS, r.HAUNTED_MANSION],
    },
    {
        name: "Vienna",
        value: "Vienna",
        country: c.AUSTRIA,
        rooms: [
            r.NIGHTMARE_HOTEL,
            r.LOST_CITY_OF_ATLANTIS,
            r.ONE_THOUSAND_AND_ONE_NIGHTS,
            r.ORIENT_EXPRESS_THE_MURDER,
        ],
    },
    {
        name: "Jakcsonville FL",
        value: "Jakcsonville FL",
        country: c.USA,
        rooms: [
            r.PHILOSOPHERS_STONE,
            r.HORROR_CIRCUS,
            r.LOST_CITY_OF_ATLANTIS,
            r.TOP_SECRET,
        ],
    },
    {
        name: "Pittsburgh PA",
        value: "Pittsburgh PA",
        country: c.USA,
        rooms: [
            r.ORIENT_EXPRESS_THE_MURDER,
            r.PHILOSOPHERS_STONE,
            r.HAUNTED_MANSION,
            r.HORROR_CIRCUS,
            r.ILLUMINATI,
        ],
    },
    {
        name: "Burlington CA",
        value: "Burlington CA",
        country: c.CANADA,
        rooms: [
            r.PHILOSOPHERS_STONE_2,
            r.NIGHTMARE_HOTEL,
            r.TEMPLE_OF_THE_SKULL,
            r.TOYMAKER,
            r.DRACULA,
            r.ALICE_IN_WONDERLAND,
            r.TOP_SECRET,
        ],
    },
];

export default store;
