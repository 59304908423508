import packageJson from "../../package.json";

export function getVersion() {
    try {
        return packageJson.version;
    } catch (error) {
        console.error("Error reading package.json:", error.message);
        return null;
    }
}
