import {
    deleteReport,
    createReport,
    updateReport,
    getReports,
    getReportById,
} from "services/reporting";

export const handleDelete = async (reportId, setReports) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
        console.log("Delete report with ID:", reportId);
        const res = await deleteReport(reportId);
        if (res?.message === "Success") {
            setReports((prevReports) =>
                prevReports.filter((rep) => rep.report_id !== reportId)
            );
            window.location.assign("/reporting");
        } else {
            window.confirm(res);
        }
    }
};

export const handleChange = (setExisting, e) => {
    const { name, value } = e.target;
    setExisting((prev) => ({
        ...prev,
        [name]: value,
    }));
};

export const handleChecklistChange = (
    index,
    field,
    value,
    setExisting,
    existing
) => {
    const updatedChecklist = [...existing.checklist];
    updatedChecklist[index][field] = value;
    setExisting((prev) => ({
        ...prev,
        checklist: updatedChecklist,
    }));
};

export const submit = async (existing) => {
    const res = await createReport(existing);
    if (res?.message === "success") {
        setTimeout(window.location.assign(`/reporting`), 4000);
    }
};

export const handleSave = async (report) => {
    const res = await updateReport(report);
    if (res?.message === "Success") {
        window.location.assign("/reporting");
    } else {
        res?.message && window.confirm(res?.message);
    }
};

export const getR = async (setReports) => {
    try {
        const res = await getReports();
        const rows = res?.reports;
        setReports(rows);
    } catch (error) {
        console.error(error);
    }
};

export const getRepById = async (id, setReport) => {
    const res = await getReportById(id);
    setReport(res[0]);
};
