export const topSecretItems = [
    "CONSOLE",
    "MAGIC RING",
    "TOXIC BOX",
    "SAFEBOX M/C",
    "SHOWCASE SOLENOID & LED STRIPS",
    "WOODEN BOX M/C",
    "FINGERPRINT SCANNER",
    "REMOTE FINGERPRINT COPIER",
    "SAFE DOOR EL/ET",
    "TAPE RECORDER",
    "FOG MACHINE",
    "ELECTRONICS MAGIC RING 42x33.5",
];
