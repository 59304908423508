import { ProvideMoreInfo } from "constants/constants";

export const sharedProperties = {
    other: { type: "string" },
    solution: { type: "string" },
};

export const submitButtonConfig = {
    "ui:submitButtonOptions": {
        submitText: "Submit",
        norender: false,
        props: {
            disabled: false,
            className: "submitButton",
        },
    },
};

export const sharedUiSchema = {
    other: {
        "ui:placeholder": ProvideMoreInfo,
        "ui:widget": "textarea",
    },
    solution: {
        "ui:placeholder": ProvideMoreInfo,
        "ui:widget": "textarea",
    },
};
