import config from "main-config.json";

export const getReports = async () => {
    try {
        return await fetch(`${config.failures_api_url}/reporting/get.php`, {
            method: "GET",
        }).then((res) => res.json());
    } catch (error) {
        console.error(error);
    }
};

export const getReportById = async (id) => {
    try {
        return await fetch(
            `${config.failures_api_url}/reporting/getById.php?id=${id}`,
            {
                method: "GET",
            }
        ).then((res) => res.json());
    } catch (error) {
        console.error(error);
    }
};

export const createReport = async (report) => {
    try {
        return await fetch(`${config.failures_api_url}/reporting/create.php`, {
            method: "POST",
            body: JSON.stringify(report),
        }).then((res) => res.json());
    } catch (error) {
        console.error(error);
    }
};

export const updateReport = async (report) => {
    try {
        return await fetch(`${config.failures_api_url}/reporting/update.php`, {
            method: "POST",
            body: JSON.stringify({
                ...report,
                id: report.report_id,
            }),
        }).then((res) => res.json());
    } catch (error) {
        console.error(error);
    }
};

export const deleteReport = async (reportId) => {
    try {
        return await fetch(`${config.failures_api_url}/reporting/delete.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
                id: reportId,
                role: JSON.parse(window.localStorage.getItem("userDetails"))
                    .role,
            }),
        }).then((res) => res.json());
    } catch (error) {
        console.error(error);
    }
};
