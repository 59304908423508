export const lmdpItems = [
    'RECEPTION DESK WITH 24" MONITOR IF IT HAS AHS',
    "PHONES VITRINE",
    "COLUMN WITH LASERS",
    "COLUMN WITH PHOTORESISTORS",
    "OFFICE DESK",
    "BOOKCASE",
    "BANKSY IR FRAME",
    "PNEUMATIC FRAME",
    "ELECTRICITY METAL PANEL",
    "FRAMES POSTERS",
    'CONSOLE MONEY PRINT WITH 24" MONITOR',
    'CONSOLE NUMBERS PANELS AND 32" MONITOR FOR VIDEO',
    "SAFE DOOR",
    "AIR VENTILATION",
    "STROBO, RADAR, MUSHROOM BUTTON",
    "FOG MACHINE CASE",
    "CHEST M/C",
    "1x FOG MACHINE (WITH SHIPPING LABEL ON THE BOX)",
    "ELECTRONICS LMDP RECEPTION BOTTOM 54x27",
    "ELECTRONICS LMDP RECEPTION WALL 43.5x27",
    "ELECTRONICS LMDP BOOKCASE 68x27",
    "ELECTRONICS LMDP CONSOLE 152.5x50",
    "ELECTRONICS LMDP IR FRAME",
    "ELECTRONICS LMDP MONEY PRINT",
    "LMDP BOOKCASE KEYSWITCH 10x10cm",
];
