export const philosopherstone2_4_SECRET_KEEPERS = [
    "AHS CABINET & AHS FRAME MONITOR",
    "CHEST EL/ET",
    "MANDRAKES M/C",
    "MANDRAKES BOOKCASE",
    "MIRROR LED STRIP",
    "METRONOME",
    "GOLD TOWER WHEEL LED STRIP",
    "DESK",
    "BOOK M/Cs",
    "STATUES CUPBOARD M/C",
    "CUPBOARD GREEN LED TAPE",
    "GUARDIAN BLUE WITH UTP CABLE 3m FOR RFID",
    "GUARDIAN GREEN WITH UTP CABLE 3m FOR RFID",
    "GUARDIAN ORANGE WITH UTP CABLE 3m FOR RFID",
    "GUARDIAN RED WITH UTP CABLE 3m FOR RFID",
    "GUARDIAN BLUE BASE",
    "GUARDIAN GREEN BASE",
    "GUARDIAN ORANGE BASE",
    "GUARDIAN RED BASE",
    "WANDS CABINETS - MAIN ELECTRONICS & EL/ET CONTROL OF ELECTRONICS POSITION",
    "WANDS CABINETS - EL/ET",
    'PENSIEVE 32" MONITOR',
    "RULES PLATES BASES M/C x6",
    "RULES PLATES WITH MAGNETS x6",
    "TOUCH PANEL FOR WAND CABINET",
    "RFID STATUES TABLE",
    "VANISHING CUPBOARD",
    "RISING COMPARTMENT - LED FRAME",
    "GOLDEN BALL",
    "SNAKE EMERGING",
    "PUZZLE",
    "WAND TRAINER",
    "GEMS BUTTONS",
    "DOOR PASSAGE EL/ET",
    "ELECTRONICS SNAKE EMERGING 33X54",
    "ELECTRONICS DESK 30X47.5",
    "ELECTRONICS PENSIEVE 43X55",
    "ELECTRONICS MAIN 115X38",
    "ELECTRONICS MANDRAKES 72X33.5",
    "ELECTRONICS RFID TABLE 42X27",
];
