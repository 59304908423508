export const philosophersStoneItems = [
    "WAND TRAINER",
    "AHS CABINET",
    "AHS MONITOR FRAME",
    "HARP",
    "HARP BASE",
    "RFID FRAME - DOOR",
    "PASSAGE DOGBOX",
    "PASSAGE DOOR",
    "CHEST",
    "SHELF VIDEO PLAYERS",
    "SHELF PCB HOLDING HANDS",
    '2x MONITOR FRAMES 32"',
    "2x HANDS KEYS",
    "CHESSBOARD",
    "RFID TUBES - BOOKCASE",
    "RFID TUBES",
    "MIRROR",
    "MIRROR BASE",
    "SNAKES",
    "11x CANDLES ON WOODEN BASE",
    "2x FOG MACHINES (WITH SHIPPING LABEL ON BOX)",
    "BOOKCASE EL/ET",
    "ELECTRONICS SCHOOL OF MAGIC MIRROR 65x33.5",
    "ELECTRONICS SCHOOL OF MAGIC HARP 46x36.5",
    "ELECTRONICS SCHOOL OF MAGIC BOOKS POWER SOCKET, USB 22.5x22.5",
    "ELECTRONICS SCHOOL OF MAGIC TUBES 89x29",
    "ELECTRONICS SCHOOL OF MAGIC BOOKCASE 96x22.5",
    "ELECTRONICS SCHOOL OF MAGIC SHELF 21x12 cm",
    "ELECTRONICS CHESSBOARD 33.5 x 25",
];
