export const toymakerItems = [
    "COUNTER",
    "DOLLHOUSE FURNITURE",
    "DOLLHOUSE LEDS",
    "AHS DOOR",
    'TV DOOR WITH 22" MONITOR',
    "BED",
    "STICKS BOX",
    "SHELF WITH VASES",
    "THEATER OF SHADOWS",
    "BALLS GAME",
    "ABOMINATION JUMPSCARE",
    "JACK IN THE BOX JUMPSCARE",
    "SHELVES PASSAGE",
    "MASKS - ROTATED DISKS",
    "PIANO",
    "SHELVES WITH PIANO",
    "SHELVES WITH MUSIC BOX (SPK & RJ45)",
    "SHELVES WITH UV SPOT & CUBES MAGNET CONTACTS",
    "MUSIC BOX WITH LEDS & M/C",
    "PYRAMID TOUCH",
    "LED ON SLAT FOR PANEL CORK 80cm WARM WHITE",
    "FAIRYTALES LEDS",
    "SPEAKERS LSP33 IN GREY BOXES",
    "CEILING FAN",
    "SWITCH PANEL",
    "CUBES WITH MAGNETS",
    "BOX WITH E27 RETRO BULB & BUZZER FOR DOOR 2ND ROOM",
    "FOG MACHINE",
    "MOUNTING DEVICE FOR 'HEADS' ON BED & VASES",
    "2 BULBS E14 WITH 1m CABLE FOR DOLL HEADS - DELIVERY TO MANUFACTURER",
    "WOOD FOR VASES 70x42",
    "WOOD FOR THEATER OF SHADOWS 55x62",
    "WOOD FOR BED",
    "WOOD Main 55x60",
    "WOOD JACK IN A BOX 48x40",
    "WOOD COUNTER 54x67 & 15x10",
    "WOOD WORKBENCH 57x35.5",
    "WOOD PYRAMID CUBES 41x41 5mm plywood",
    "WOOD ABOMINATION JUMPSCARE 45x55",
    "WOOD BALL GAME ELECTRONICS 50x24",
    "WOOD BALL GAME SOLENOIDS 24x10",
    "WOOD DOLLHOUSE 40x70",
    "WOOD TV DOOR 46x38 cm",
];
