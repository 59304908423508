import PropTypes from "prop-types";

const DropDown = ({
    name,
    handleChange,
    children,
    defaultValue,
    isInCompleted,
}) => {
    return (
        <>
            <label className='label'> {name} </label>
            <div className='optionDropDown'>
                <select
                    name={name}
                    disabled={isInCompleted}
                    defaultValue={defaultValue}
                    onChange={(e) => handleChange(e)}>
                    {children}
                </select>
            </div>
        </>
    );
};

DropDown.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.element,
    isInCompleted: PropTypes.bool,
    defaultValue: PropTypes.string,
};

export default DropDown;
