export const draculaItems = [
    "Vlad Tepes Portrait - RFID",
    "4x Coffins",
    "Dracula's Grave - Coffin",
    "6 Candles for Sad",
    "Wooden lamp with E14 socket and 12VDC bulb",
    "RFID Puzzle Table",
    "Sliding Frame",
    "3x Switches",
    "AHS Monitor Frame",
    "AHS Cabinet",
    "Disk M/C",
    "Door M/C - Double Door",
    "RFID Cube",
    "Bookcase",
    "Books Switches",
    "Wooden Box 1st Room",
    "Coffin's Sticks",
    "Table",
    "Suitcase",
    "6 Knobs Switches",
    "Candles Altar",
    "6 Candles for Fireplace",
    "Electromagnet Door Passage",
    "Electronics Dracula Bookcase 44x25.5",
    "Electronics Dracula Bookcase Power Supplies 46x24",
    "Electronics Dracula Sliding Frame 60x36",
    "Electronics Dracula Puzzle Table 50x28",
    "Electronics Dracula Central Coffin 52x34",
    "Electronics Dracula Central Coffin Power Supplies 40x48",
    "Electronics Dracula Central Coffin Speaker & Socket 30x40",
    "Electronics Dracula Coffins 30x18",
    "Electronics Dracula Box with Light 35x30",
    "Electronics Dracula Vlad Tepes 47x24",
    "Electronics Dracula Table 103x61",
    "Electronics Dracula Wood Fireplace Candles 74x16",
];
