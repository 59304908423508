import { JsSchemaMap } from "schemas";
import { sharedProperties } from "schemas/sharedSchema";

export const createFormState = (isCreating, data = {}) => {
    const defaultState = {
        country: "GREECE",
        store: "Aigaleo GO",
        room: "CHOCOLATE FACTORY 2", // Default JACK THE RIPPER
        details: "",
        department: "electronic",
        assistant: "",
        reporter: "",
        solution: "",
        created_date: "",
        completed_date: "1970-01-01",
        status: "pending",
        errorMessage: "",
        successMessage: "",
    };

    return isCreating
        ? defaultState
        : {
              ...defaultState,
              country: data.country,
              store: data.store,
              room: data.room,
              details: JSON.parse(data?.details || "{}"),
              department: data.department,
              assistant: data.assistant,
              reporter: data.client,
              solution: data.solution,
              created_date: data.created_date,
              completed_date: data.completed_date,
              status: data.status,
          };
};

export const resetMessageAfterDelay = (setFormState) => {
    setTimeout(() => {
        setFormState((prevState) => ({
            ...prevState,
            successMessage: "",
            errorMessage: "",
        }));
    }, 3000);
};

export const validateForm = (formData) => {
    const requiredFields = [formData.country, formData.store, formData.room];

    const schemaProperties = Object.keys(
        JsSchemaMap().get(formData.room)?.properties || sharedProperties
    ).filter((key) => key !== "solution");

    const matchingIssues = schemaProperties.reduce((acc, key) => {
        if (formData[key]?.trim && formData[key].trim() !== "") {
            acc[key] = formData[key];
        }
        return acc;
    }, {});

    const hasAtLeastOneIssue = Object.keys(matchingIssues).length > 0;

    return {
        isValid:
            requiredFields.every((field) => field.trim() !== "") &&
            hasAtLeastOneIssue,
        matchingIssues,
    };
};

export const backButton = (resetShowTicketForm) => {
    resetShowTicketForm();
};
