import CheckBoxes from "Components/Shared/Checkboxes/checkBoxes";
import "Components/Shared/Checkboxes/checkBoxes.css";

const renderCheckBoxes = (
    checkboxes,
    selectedDepartment,
    handleDepartmentType
) =>
    checkboxes.map((item, index) => (
        <CheckBoxes
            key={index}
            name={item}
            isDisabled={false}
            checked={selectedDepartment}
            handleDepartmentType={handleDepartmentType}
        />
    ));

export default renderCheckBoxes;
