import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { useEffect, useState } from "react";
import { handleDelete } from "Helpers/reporting";
import { useParams } from "react-router";
import {
    handleChecklistChange,
    handleSave,
    getRepById,
} from "Helpers/reporting";

import "./index.css";

const ViewReport = () => {
    const [report, setReport] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        getRepById(id, setReport);
    }, [id]);

    const handleChange = (field, value) => {
        setReport((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    if (!report) return <></>;
    return (
        <Card
            color='neutral'
            variant='soft'
            sx={{
                margin: "auto",
                width: "80%",
                gridTemplateColumns: "none",
                minHeight: "25em",
            }}>
            <CardContent>
                <Typography level='title-md'>Report ID</Typography>
                <Input
                    disabled
                    value={report.report_id}
                />
                <Typography level='title-md'>Reporter ID</Typography>
                <Input
                    disabled
                    value={report.reporter_id}
                />

                <Typography level='title-md'>Label</Typography>
                <Input
                    value={report.label}
                    onChange={(e) => handleChange("label", e.target.value)}
                />

                <Typography level='title-md'>Installation</Typography>
                <Input
                    value={report.installation}
                    onChange={(e) =>
                        handleChange("installation", e.target.value)
                    }
                />

                <Typography level='title-md'>Room Name</Typography>
                <Input
                    value={report.room_name}
                    onChange={(e) => handleChange("room_name", e.target.value)}
                />

                <Typography level='title-md'>Status</Typography>
                <Input
                    value={report.status}
                    onChange={(e) => handleChange("status", e.target.value)}
                />

                <Typography level='title-md'>Checklist</Typography>
                {report.checklist?.map((comp, index) => (
                    <div
                        key={index}
                        style={{ marginBottom: "1em" }}>
                        <Input
                            value={comp.component}
                            placeholder='Component'
                            onChange={(e) =>
                                handleChecklistChange(
                                    index,
                                    "component",
                                    e.target.value,
                                    setReport,
                                    report
                                )
                            }
                        />
                        <Input
                            value={comp.status}
                            placeholder='Status'
                            onChange={(e) =>
                                handleChecklistChange(
                                    index,
                                    "status",
                                    e.target.value,
                                    setReport,
                                    report
                                )
                            }
                        />
                    </div>
                ))}

                <Typography level='title-md'>Created At</Typography>
                <Input
                    disabled
                    value={report.created_at}
                />
                <div
                    className='action'
                    style={{ marginTop: "1em" }}>
                    <Button
                        color='primary'
                        onClick={() => {
                            handleSave(report);
                        }}>
                        Update
                    </Button>
                    <Button
                        color='danger'
                        onClick={(e) => {
                            e.preventDefault();
                            handleDelete(report.report_id, () => {});
                        }}>
                        Delete
                    </Button>
                    <Button
                        color='danger'
                        onClick={() => window.location.assign("/reporting")}>
                        Leave
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default ViewReport;
