import "css/cards.css";
import "react-bootstrap-accordion/dist/index.css";
import { Accordion } from "react-bootstrap-accordion";
import PropTypes from "prop-types";

const GenerateTicket = ({ item, editTicket, viewTicket, deleteTicket }) => {
    const editHandler = (item) => {
        editTicket(item);
    };

    const viewHandler = (item) => {
        viewTicket(item);
    };

    const deleteHandler = (item) => {
        deleteTicket(item);
    };

    const { store, room, assistant, report, issue, version, details } = item;
    const issuesDetails = JSON.parse(details || "{}");

    return (
        <div className='holder'>
            <li className='cards'>
                <div className='card'>
                    <div className='card__title'> </div>
                    <Accordion
                        title={`${store} / ${room} / ${assistant} / ${issue}`}>
                        {deleteTicket && (
                            <div
                                onClick={() => deleteHandler(item)}
                                className='delete__button'>
                                X
                            </div>
                        )}
                        <div className='card__content'>
                            {version ? (
                                <div className='card__text'>
                                    <p>Details:</p>
                                    <ul>
                                        {Object.entries(issuesDetails).map(
                                            ([key, value], index) => (
                                                <li key={index}>
                                                    <strong>{key}:</strong>{" "}
                                                    {value}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <p className='card__text'> Report: {report} </p>
                            )}
                            {editTicket ? (
                                <button
                                    onClick={() => editHandler(item)}
                                    className='btn btn--block card__btn'>
                                    EDIT
                                </button>
                            ) : (
                                <button
                                    onClick={() => viewHandler(item)}
                                    className='btn btn--block card__btn'>
                                    VIEW
                                </button>
                            )}
                        </div>
                    </Accordion>
                </div>
            </li>
        </div>
    );
};

GenerateTicket.propTypes = {
    item: PropTypes.object.isRequired,
    editTicket: PropTypes.func,
    viewTicket: PropTypes.func,
    deleteTicket: PropTypes.func,
};

export default GenerateTicket;
