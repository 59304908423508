export const chocolate2Items = [
    "AHS",
    "CHEST M/C",
    "ONE WAY MIRROR LED TAPE WARM WHITE",
    "SHELF WITH CANS AND CABINET",
    "SHELF WITH CUPCAKES",
    "STOVE",
    "ROBOT - BALLS ENTRY",
    "ROBOT - BALLS EXIT",
    "SHAPES CONSTRUCTION",
    "TOKEN MACHINE",
    "MEMORY GAME",
    "MEMORY GAME - SLIDING FRAMES",
    "STATION 1",
    "STATION 2",
    "STATION 3",
    "STATION 4",
    "BLACKBOARD",
    "ADDRESSABLE LED STRIP 24V IN CHANNEL FOR STATIONS",
    "WOOD ROBOT BALLS OUT 27x25",
    "WOOD ROBOT BALLS IN 53.5x37",
    "WOOD SHAPES 44x23",
    "WOOD SHELF RFIDs 78x23",
    "WOOD SHELF WITH CUPCAKES RFID 78x16.5 x2",
    "WOOD TOKEN MACHINE 28x16",
    "WOOD MEMORY GAME 63x35 42x42",
    "WOOD MAIN ELECTRONICS 70x65",
    "WOOD OVEN 51x37 40x40",
    "WOOD STATIONS 25x36 x4",
];
