export const hauntedMansionItems = [
    "AHS CABINET",
    "AHS FRAME MONITOR",
    "MIRROR",
    "CLOSET",
    "2x EL/ET BEAMS",
    "RADIO",
    "3x CUPBOARDS - CANDLES & LED STRIP",
    "SKULLS",
    "SKULLS ALTAR",
    "CABINET PASSAGE - MAIN ELECTRONICS",
    "RADIO & HAND PNEUMATIC",
    "SHELF EL/ET 1",
    "SHELF EL/ET 2",
    "SHUTTERS EL/ET",
    "ELECTRONICS MANSION DRESSER 48x31.5",
    "ELECTRONICS MANSION MIRROR 35x32",
    "ELECTRONICS MANSION MAIN 94x34",
    "ELECTRONICS MANSION BUFFET 55x34",
    "ELECTRONICS MANSION BUFFET FOR THE EXTENSION CORD 28x29",
];
