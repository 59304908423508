export const circusItems = [
    "Garland 1st room",
    "ICE CREAM TROLLEY",
    "AHS CABINET",
    "AHS MONITOR FRAME (WAGON)",
    "TICKET KIOSK",
    "WAGON",
    "SPOTLIGHTS WAGON ROOF",
    "CIRCUS GATE M/C DOOR",
    "WHEEL JUMPSCARE",
    "PUPPET - MARIONETTE",
    "HAMMER STRIKE",
    "FORTUNE TELLER",
    "CLOWN GATE DOOR",
    "CLOWN JUMPSCARE",
    "METAL DOOR",
    "TABLE",
    "MIRROR",
    "CLOCK JUMPSCARE",
    "GOLD CHANNEL 40x25 ~40m FOR PAINTING",
    "Ω LIGHTS",
    "GRAY BOX WITH GOLD SOCKET AND BUZZER",
    "ELECTRONICS CIRCUS FORTUNE TELLER 71x43",
    "ELECTRONICS CIRCUS WAGON 58x50",
    "ELECTRONICS CIRCUS JUMPSCARE 55x33",
    "ELECTRONICS CIRCUS PUPPET 77.5x15",
    "ELECTRONICS CIRCUS HAMMER 36.5x32",
    "ELECTRONICS CIRCUS ICE CREAM SWITCHES 38x19",
    "ELECTRONICS CIRCUS ICE CREAM 60x50",
    "ELECTRONICS CIRCUS WHEEL 40x24",
    "CONTACTS ON WOOD FOR HAMMER STRIKE 90x8cm 1.2 THICK",
    "POWER SUPPLY IN WOOD 34x44 FOR HAMMER STRIKE",
];
