import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    Option,
} from "@mui/joy";
import { useState } from "react";
import { MockedReport } from "constants/constants";
import { submit, handleChange, handleChecklistChange } from "Helpers/reporting";
import "./index.css";

const CreateReport = () => {
    const [existing, setExisting] = useState(MockedReport);

    return (
        <div className='create-report'>
            <FormControl>
                <FormLabel>Label</FormLabel>
                <Input
                    name='label'
                    placeholder='Report name'
                    value={existing.report_name}
                    onChange={(e) => {
                        handleChange(setExisting, e);
                    }}
                />
                <FormHelperText>
                    This is the name given to the report.
                </FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel>Installation</FormLabel>
                <Input
                    name='installation'
                    placeholder='Store name'
                    value={existing.installation}
                    onChange={(e) => {
                        handleChange(setExisting, e);
                    }}
                />
                <FormHelperText>
                    This is the name of the store in question.
                </FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel>Status</FormLabel>
                <Input
                    name='status'
                    placeholder='Status'
                    value={existing.status}
                    onChange={(e) => {
                        handleChange(setExisting, e);
                    }}
                />
                <FormHelperText>
                    This is the current status of the report.
                </FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel>Room Name</FormLabel>
                <Input
                    name='room_name'
                    placeholder='Room name'
                    value={existing.room_name}
                    onChange={(e) => {
                        handleChange(setExisting, e);
                    }}
                />
                <FormHelperText>This is the room name.</FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel>Reporter</FormLabel>
                <Input
                    name='reporter'
                    placeholder='Reporter'
                    value={existing.reporter}
                    onChange={(e) => {
                        handleChange(setExisting, e);
                    }}
                />
                <FormHelperText>
                    This is the name of the person making this report.
                </FormHelperText>
            </FormControl>

            <FormLabel>Checklist</FormLabel>
            {existing.checklist.map((item, index) => (
                <div
                    key={index}
                    className='checklist-item'>
                    <FormControl>
                        <Input
                            placeholder='Component'
                            value={item.component}
                            onChange={(e) =>
                                handleChecklistChange(
                                    index,
                                    "component",
                                    e.target.value,
                                    setExisting,
                                    existing
                                )
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <Select
                            value={item.status ?? ""}
                            onChange={(e, val) => {
                                console.log(e?.target?.textContent);
                                handleChecklistChange(
                                    index,
                                    "status",
                                    val,
                                    setExisting,
                                    existing
                                );
                            }}>
                            <Option value='pending'>Pending</Option>
                            <Option value='completed'>Completed</Option>
                            <Option value='processing'>Processing</Option>
                        </Select>
                    </FormControl>
                    <FormHelperText>
                        This is the list of things to report.
                    </FormHelperText>
                </div>
            ))}
            <div className='actions'>
                <Button
                    className='actions-button'
                    color='success'
                    onClick={(e) => {
                        e.preventDefault();
                        submit(existing);
                    }}>
                    Submit
                </Button>
                <Button
                    className='actions-button'
                    color='danger'
                    onClick={() => {
                        window.location.assign(`/reporting`);
                    }}>
                    Leave
                </Button>
            </div>
        </div>
    );
};

export default CreateReport;
