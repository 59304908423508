import { Link } from "react-router-dom";
import Login from "Components/Login/Login";
import Logo from "assets/logo.png";
import HomeImg from "assets/addbutton.svg";
import PendingImg from "assets/pending.svg";
import CompletedImg from "assets/completed.svg";
import ReportingImg from "assets/reporting.svg";

import "./style.css";
import PropTypes from "prop-types";

const NavBar = ({
    handleLogin,
    handleLogout,
    loginProfile,
    pendingsStatus,
}) => {
    return (
        <nav
            className='row navBar'
            id='top'
            style={{
                alignItems: "center",
                justifyContent: "space-around",
            }}>
            <div>
                <Link to='/'>
                    <img
                        className='imgFluid'
                        src={Logo}
                        alt=''
                    />
                </Link>
            </div>

            <ul className='menuLinks'>
                <li>
                    <Link
                        to='/form'
                        replace>
                        <img
                            className='navBarplus'
                            src={HomeImg}
                            alt=''
                        />
                    </Link>
                    <div
                        className='new'
                        id='perspective-text'>
                        <p
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                            }}>
                            Add
                        </p>
                    </div>
                </li>
                <li>
                    <Link
                        to='/'
                        replace>
                        <img
                            className='navBarIcons'
                            src={PendingImg}
                            alt=''
                        />
                        <div className='pendingsCounter'>{pendingsStatus}</div>
                    </Link>
                    <div
                        className='complete'
                        id='perspective-text'>
                        <p style={{ color: "#fff" }}> Pending</p>
                    </div>
                </li>
                <li>
                    <Link
                        to='completed'
                        replace>
                        <img
                            className='navBarIcons'
                            src={CompletedImg}
                            alt=''
                        />
                    </Link>
                    <div
                        className='complete'
                        id='perspective-text'>
                        <p style={{ color: "#fff" }}>Completed</p>
                    </div>
                </li>
                <li>
                    <Link
                        to='reporting'
                        replace>
                        <img
                            className='navBarIcons'
                            src={ReportingImg}
                            alt=''
                        />
                    </Link>
                    <div
                        className='complete'
                        id='perspective-text'>
                        <p style={{ color: "#fff" }}>Reporting</p>
                    </div>
                </li>
            </ul>
            <Login
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                loginProfile={loginProfile}
            />
        </nav>
    );
};

NavBar.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    loginProfile: PropTypes.string,
    pendingsStatus: PropTypes.number,
};

export default NavBar;
