import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { Button } from "@mui/joy";
import "./index.css";
import { useEffect, useState } from "react";
import { getR } from "Helpers/reporting";
import { Link } from "react-router-dom";

const Reporting = () => {
    const [reports, setReports] = useState([]);

    useEffect(() => {
        getR(setReports);
    }, []);

    if (!reports) return <></>;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    minHeight: "45px",
                    width: "50%",
                    position: "relative",
                    right: "-50%",
                    justifyContent: "flex-end",
                    gap: "1em",
                    padding: "2rem",
                    margin: "1em 0",
                }}>
                <Link to='/reporting/create'>
                    <Button
                        className='actions-button'
                        color='danger'>
                        New Report
                    </Button>
                </Link>
            </div>
            <Sheet
                color='neutral'
                variant='solid'
                sx={{
                    height: 300,
                    overflow: "auto",
                    width: "100%",
                    display: "flex",
                }}>
                <Table
                    aria-label='Reportings List'
                    stickyHeader
                    stickyFooter
                    stripe='odd'
                    hoverRow
                    color='white'>
                    <thead>
                        <tr>
                            <th className='t-header'>Row</th>
                            <th className='t-header'>ReportID</th>
                            <th className='t-header'>Label</th>
                            <th className='t-header'>Installation</th>
                            <th className='t-header'>Status</th>
                            <th className='t-header'>Reporter</th>
                            <th className='t-header'>Operations</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((row, idx) => (
                            <tr
                                key={idx}
                                className='cursor-pointer'>
                                <td className='t-body'>{idx + 1}</td>
                                <td className='t-body'>{row.report_id}</td>
                                <td className='t-body'>{row.label}</td>
                                <td className='t-body'>{row.installation}</td>
                                <td className='t-body'>{row.status}</td>
                                <td className='t-body'>{row.report_id}</td>
                                <td className='t-body'>
                                    <Link
                                        className='w-[100%] inline-block'
                                        to={`/reporting/${row.report_id}`}>
                                        <Button
                                            className='w-[90%]'
                                            color='primary'>
                                            More
                                        </Button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td
                                colSpan={7}
                                className='t-body'
                                style={{ textAlign: "center" }}>
                                ...
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Sheet>
        </div>
    );
};

export default Reporting;
