export const twentyThousandLeaguesItems = [
    "MAP",
    "LOW OXYGEN BOX",
    "SUBMARINE MAP",
    "OXYGEN LEVEL LOW PANEL",
    "AHS DOOR",
    "OXYGEN VALVE 1",
    "OXYGEN VALVE 2",
    "RGB LED TAPE & CONTROLLER SHOWCASE",
    'CONSTRUCTION WITH TWO PORTHOLES & 55" TV',
    'FRONT WINDOW SCREEN CONSTRUCTION 55" TV',
    "SHELF JUMPSCARE 1",
    "SHELF JUMPSCARE 2",
    "WAR CONTROL PANEL 1",
    "WAR CONTROL PANEL 2",
    "MAIN CONSOLE",
    "DESK",
    "KNOTS MAZE",
    "CHEST M/C",
    "DOOR EL/ET x2",
    "EMERGING",
    "PROJECTOR BOX",
    "WOOD FOR ELECTRONICS OF WAR PANEL x2",
    "WOOD FOR ELECTRONICS OF MAP GRID 80x60",
    "WOOD FOR ELECTRONICS OF MAP OXYGEN 52x72",
    "WOOD FOR ELECTRONICS OF CONSOLE 64x39",
    "WOOD FOR ELECTRONICS OF KNOTS MAZE 45x35",
    "WOOD FOR ELECTRONICS OF DESK 50x35",
    "WOOD FOR ELECTRONICS OF EMERGING 30x30",
];
