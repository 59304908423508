import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import GenerateTicket from "utils/generateTicket";
import "css/cards.css";
import SearchBar from "Components/Shared/SearchBar/searchBar";
import config from "main-config.json";
import TicketForm from "../TicketForm/TicketForm";
import TicketTemplate from "Components/TicketTemplate/TicketTemplate";
import { backButton } from "Helpers/TicketForm";
import PropTypes from "prop-types";

const URL = config.failures_api_url;

const Pending = ({
    handleTicketCounter,
    pendingsStatus,
    profile,
    isAuth,
    setTicketCounter,
    setCauses,
    ticketData,
    setTicketData,
}) => {
    const [ticket, setTicket] = useState([]);
    const [filteredTicket, setFilteredTicket] = useState([]);
    const [groupByTicket, setGroupByTicket] = useState([]);
    const [itemData, setItemData] = useState({});
    const [search, setSearch] = useState("");
    const [showPendings, setShowPendings] = useState(true);
    const [showTicketForm, setShowTicketForm] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const location = useLocation();

    const editTicket = (itemData) => {
        setItemData(itemData);
        setShowTicketForm(true);
        setShowPendings(false);
        setHasSearched(false);
    };

    const deleteTicket = (itemData) => {
        setItemData(itemData);
        setDeleteConfirmation(true);
    };

    const removeTicketFromList = (id) => {
        const newTicketList = ticket.filter((el) => el.id !== id);
        const newFilteredTicket = filteredTicket.filter((el) => el.id !== id);

        setTicket(newTicketList);
        setFilteredTicket(newFilteredTicket);

        handleTicketCounter(pendingsStatus - 1);
    };

    const submitDelete = (e) => {
        e.preventDefault();
        const { id } = itemData;

        const formData = new FormData();
        formData.append("id", id);

        fetch(`${URL}/ticket/deleteTicket.php`, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === "success") {
                    setDeleteConfirmation(false);
                    removeTicketFromList(id);
                }
            })
            .catch((error) => {
                console.error("Error deleting ticket:", error);
            });
    };
    const removeDuplicates = (tickets) => {
        const seen = new Set();
        return tickets.filter((ticket) => {
            const identifier = `${ticket.store}-${ticket.room}-${ticket.id}`;
            if (seen.has(identifier)) {
                return false;
            }
            seen.add(identifier);
            return true;
        });
    };
    const showPendingTickets = (ticketsToMap) =>
        ticketsToMap.map((item) => (
            <GenerateTicket
                key={item.id || `${item.store}-${item.room}-${item.id}`}
                item={item}
                editTicket={item.solution === "" ? editTicket : undefined}
                deleteTicket={
                    profile === "gameoveradmin" ? deleteTicket : undefined
                }
            />
        ));

    const updateSearch = (event) => {
        const searchTerm = event.target.value.trim();
        setSearch(searchTerm);

        if (searchTerm) {
            setHasSearched(true);

            const tempSearchTickets = ticket.filter(
                (t) =>
                    (t.store && t.store.includes(searchTerm)) ||
                    (t.room && t.room.includes(searchTerm)) ||
                    (t.assistant && t.assistant.includes(searchTerm))
            );

            const uniqueTickets = removeDuplicates(tempSearchTickets);
            setGroupByTicket(uniqueTickets);
        } else {
            setHasSearched(false);
            setGroupByTicket(filteredTicket);
        }

        setShowPendings(true);
    };

    const resetShowTicketForm = () => {
        setShowTicketForm(false);
        setShowPendings(true);
        setDeleteConfirmation(false);
    };

    useEffect(() => {
        const getData = async () => {
            const formData = new FormData();
            formData.append("status", "pending");

            try {
                const res = await fetch(
                    `${URL}/ticket/fetchPendingTickets.php`,
                    {
                        method: "POST",
                        body: formData,
                    }
                );

                const response = await res.json();
                if (response.message !== "No Pending Results!") {
                    setTicket(response.ticket);
                    setFilteredTicket(response.ticket);
                    setShowPendings(true);
                    setTicketCounter(response.ticket.length);
                    handleTicketCounter(response.ticket.length);
                } else {
                    setShowPendings(false);
                    setTicketCounter(0);
                }
            } catch (error) {
                setTicket({ error: error });
                setCauses({ error: error });
            }
        };

        if (location.pathname === "/" && isAuth) {
            getData();
        }
    }, []);

    return (
        <div className='back'>
            {showPendings && (
                <SearchBar
                    searchInput={search}
                    updateSearch={updateSearch}
                />
            )}
            {showPendings && (
                <>
                    <div className='filterSearchContainer row-reverse'>
                        <ul className='cards scrollable'>
                            <ul className='cards scrollable'>
                                {hasSearched && groupByTicket.length < 1 ? (
                                    <p className='resultP'>
                                        {" "}
                                        No results found!{" "}
                                    </p>
                                ) : (
                                    showPendingTickets(
                                        groupByTicket.length > 0
                                            ? groupByTicket
                                            : filteredTicket
                                    )
                                )}
                            </ul>
                        </ul>
                    </div>
                </>
            )}
            {showTicketForm && (
                <>
                    {itemData.version ? (
                        <div>
                            <div
                                className='backButton'
                                onClick={() => backButton(resetShowTicketForm)}>
                                X
                            </div>
                            <TicketForm
                                data={itemData}
                                isInCompleted={false}
                                isInPending={true}
                                setTicketData={setTicketData}
                                ticketData={ticketData}
                            />
                        </div>
                    ) : (
                        <TicketTemplate
                            data={itemData}
                            calledFromCompleted={true}
                            resetShowTicketForm={resetShowTicketForm}
                            setTicketData={setTicketData}
                            ticketData={ticketData}
                        />
                    )}
                </>
            )}
            {deleteConfirmation && (
                <>
                    <div className='popupContainer'>
                        <p>
                            Are you sure you want to delete this ticket? <br />
                            <small>{"YOU CAN'T UNDO THIS ACTION!"}</small>
                        </p>
                        <div
                            id='options'
                            onClick={submitDelete}>
                            YES!
                        </div>
                        <div
                            id='options'
                            onClick={resetShowTicketForm}>
                            NO!
                        </div>
                    </div>
                    <div className='popupOverlay'></div>
                </>
            )}
        </div>
    );
};

Pending.propTypes = {
    handleTicketCounter: PropTypes.func,
    pendingsStatus: PropTypes.number,
    profile: PropTypes.string,
    isAuth: PropTypes.bool,
    setTicketCounter: PropTypes.func,
    setCauses: PropTypes.func,
    ticketData: PropTypes.array,
    setTicketData: PropTypes.func,
};

export default Pending;
