export const topSecret2Items = [
    "AHS MONITOR",
    "HORIZONTAL ARMY BOX EL/ET & LED TAPE",
    "VERTICAL ARMY BOX WITH ELECTRICAL PANEL EL/ET & M/C",
    "GREY BOX 20x12 CABLES & RGB LED FOR VERTICAL ARMY BOX",
    "METAL CELL DOOR M/C",
    "CONSOLE",
    "DESK WITH 3 CUPBOARDS",
    'LUGGAGE PC WITH 21" MONITOR',
    'SMALL SCREEN 14"',
    "VAULT DOOR EL/ET",
    "5 PROJECTION TUBES BOXES",
    "8 PHOTORESISTOR BASES",
    "8 LASER BASES",
    "LIGHT TUBES",
    "CRANKS",
    "MISSILE ROCKET (PNEUMATIC & ADD LED TAPE)",
    "MISSILE ROCKET BASE WITH ELECTRONICS, SMOKE MACHINE & POWER DYNAMIC SPEAKER",
    "CONTROL PANEL WITH KNOBS & DIGITS PANELS",
    'MONITOR FOR CONTROL PANEL 24"',
    "COMPRESSOR BOX",
    "TOXIC BOX",
    "FOG MACHINE BOX",
    "KEYBOARD FOR DESK PC (STUCK CTRL + ALT)",
    "FOG MACHINE (WITH SHIPPING LABEL ON BOX FOR LASER MAZE)",
    "FOG MACHINE (WITH SHIPPING LABEL ON BOX FOR MISSILE BASE)",
    "PIPE Ø25 15m PAINTED BLACK",
    "SPIRAL Ø25 5m PAINTED BLACK",
    "PIPE JOINTS Ø25 x10 PAINTED BLACK",
    "RED FLASH LIGHT",
    "WOOD FOR ELECTRONICS OF DESK 35x50",
    "WOOD FOR ELECTRONICS OF CONTROL PANEL LEFT BOTTOM (44x38)",
    "WOOD FOR ELECTRONICS OF CONTROL PANEL RIGHT TOP (38 WIDTH x40 HEIGHT)",
    "WOOD FOR ELECTRONICS OF CONTROL PANEL POWER SUPPLY (28x44)",
    "WOOD FOR ELECTRONICS OF ROCKET (55x55)",
    "Rocket Case for Electronics (Width 65x55, Guide 55, Screws 30)",
    "WOOD FOR ELECTRONICS OF Cranks & Tubes (96x36)",
    "WOOD FOR ELECTRONICS OF Console (100x30)",
    "Light with IP53 Speaker in Junction Box",
];
