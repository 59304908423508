import { useState, useCallback } from "react";
import "./style.css";
import timeHelper from "utils/timeHelper";
import config from "main-config.json";
import store from "utils/roomsPerStore";
import InfoTemplate from "Components/InfoTemplate";
import { JsSchemaMap, UiSchemaMap } from "schemas";
import { generateSchema, generateUiSchema } from "utils/schemaUtils";
import {
    createFormState,
    resetMessageAfterDelay,
    validateForm,
} from "Helpers/TicketForm";
import { getVersion } from "utils/getAppVersion";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import PropTypes from "prop-types";

const URL = config.failures_api_url;

const TicketForm = ({ isInPending, isInCompleted, data }) => {
    const isNotCreating = isInPending || isInCompleted;
    const isCreating = !isNotCreating;
    const version = getVersion();
    const details = isNotCreating ? JSON.parse(data?.details) : {};
    const detailsKeys = isNotCreating ? Object.keys(details) : {};

    const [formState, setFormState] = useState(() =>
        createFormState(isCreating, data)
    );
    const currentDate = timeHelper();

    const handleFormChange = useCallback((event) => {
        const { name, value } = event.target;
        setFormState((prevState) => {
            const updatedState = { ...prevState, [name]: value };

            if (name === "country") {
                const firstStore =
                    store
                        .filter((item) => item.country === value)
                        .sort((a, b) => a.name.localeCompare(b.name))[0]
                        ?.name || "";
                const firstRoom =
                    store.find((item) => item.name === firstStore)?.rooms[0] ||
                    "";

                updatedState.store = firstStore;
                updatedState.room = firstRoom;
            }

            if (name === "store") {
                const firstRoom =
                    store.find((item) => item.name === value)?.rooms[0] || "";
                updatedState.room = firstRoom;
            }

            return updatedState;
        });
    }, []);

    const handleFormDataChange = useCallback(({ formData }) => {
        setFormState((prevState) => ({
            ...prevState,
            ...formData,
        }));
    }, []);

    const handleSubmit = async ({ formData }) => {
        const updatedStatus =
            formData.solution?.trim() !== "" ? "completed" : "pending";
        const updatedCompletedDate =
            formData.solution?.trim() !== "" ? currentDate : "1970-01-01";

        const updatedFormData = {
            ...formData,
            status: updatedStatus,
            completed_date: updatedCompletedDate,
        };

        const { isValid, matchingIssues } = validateForm(
            updatedFormData,
            details,
            isInPending
        );
        if (isValid) {
            try {
                const apiUrl = `${URL}/ticket/${isInPending ? "updateTicket" : "insertTicket"}.php`;

                const formPayload = new FormData();
                formPayload.append("country", updatedFormData.country);
                formPayload.append("client", updatedFormData.reporter);
                formPayload.append("room", updatedFormData.room);
                formPayload.append("assistant", updatedFormData.assistant);
                formPayload.append("solution", updatedFormData.solution);
                formPayload.append("created_date", currentDate);
                formPayload.append(
                    "completed_date",
                    updatedFormData.completed_date
                );
                formPayload.append("store", updatedFormData.store);
                formPayload.append("status", updatedFormData.status);
                formPayload.append("version", version);
                formPayload.append("department", updatedFormData.department);
                formPayload.append("details", JSON.stringify(matchingIssues));
                if (isNotCreating) {
                    formPayload.append("id", data.id);
                }

                const response = await fetch(apiUrl, {
                    method: "POST",
                    body: formPayload,
                });

                const result = await response.json();

                if (result?.message === "success") {
                    setFormState((prevState) => ({
                        ...prevState,
                        successMessage: "Form submitted successfully!",
                        errorMessage: "",
                    }));

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setFormState((prevState) => ({
                        ...prevState,
                        errorMessage:
                            "Failed to submit the form. Please try again.",
                        successMessage: "",
                    }));

                    resetMessageAfterDelay(setFormState);
                }
            } catch (error) {
                setFormState((prevState) => ({
                    ...prevState,
                    errorMessage: `Error: ${error.message}`,
                    successMessage: "",
                }));

                resetMessageAfterDelay(setFormState);
            }
        } else {
            setFormState((prevState) => ({
                ...prevState,
                errorMessage:
                    "Please fill all required fields and provide at least one issue.",
                successMessage: "",
            }));

            resetMessageAfterDelay(setFormState);
        }
    };

    const schema = isCreating
        ? (JsSchemaMap().get(formState.room) ?? generateSchema())
        : generateSchema(
              detailsKeys,
              isNotCreating && details.other ? "" : "other"
          );

    const uiSchema = isCreating
        ? (UiSchemaMap().get(formState.room) ?? generateUiSchema())
        : generateUiSchema(
              detailsKeys,
              isNotCreating && details.other ? "" : "other"
          );

    const formData = {
        ...formState,
        ...formState.details,
        ...formState.solution,
    };

    return (
        <div className='back'>
            <div className='formContainer'>
                <InfoTemplate
                    formState={formState}
                    handleFormChange={handleFormChange}
                    isInCompleted={isInCompleted}
                />
                <div className='formItem-2'>
                    <Form
                        className={`${isInCompleted ? "removeButton" : ""}`}
                        schema={schema}
                        uiSchema={uiSchema}
                        formData={formData}
                        validator={validator}
                        onSubmit={handleSubmit}
                        onChange={handleFormDataChange}
                        disabled={isInCompleted}
                    />
                </div>
                {formState.errorMessage && (
                    <div className='popupContainer'>
                        {formState.errorMessage}
                    </div>
                )}
                {formState.successMessage && (
                    <div className='popupContainer'>
                        {formState.successMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

TicketForm.propTypes = {
    isInPending: PropTypes.bool,
    data: PropTypes.any,
    isInCompleted: PropTypes.bool,
};

export default TicketForm;
