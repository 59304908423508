export const queenAnnesItems = [
    "BARREL EL/ET",
    "HAND HOOK BASE - PROXIMITY",
    "SHELF EL/ET",
    "OCTOPUS",
    "TABLE WITH RFID & MAP",
    "TABLE EL/ET",
    "PASSAGE BAR",
    "CHEST EL/ET & ROTATED STRUCTURE",
    "WOODEN BOX - ARROWS TOUCH",
    "1x BOX M/C",
    "AHS MONITOR FRAME",
    "AHS CABINET",
    "2x CEILING LED PANEL",
    "TABLE MOTOR",
    "PUZZLE SKULLS",
    "SKULL LOCK M/C",
    "WIND WOOD M/C",
    "SHIP FLAG SWITCH",
    "PASSAGE EL/ET - MAIN ELECTRONICS",
    "BEAM EL/ET PASSAGE",
    "HOLEBOX - LED STRIP & BUTTON",
    "ROTATED DISK",
    "2pcs 5m LED STRIP WARM WHITE FOR STAINED GLASS IN BOX",
    "CEILING LIGHT OF 3rd SPACE",
    "WOOD FOR ELECTRONICS CABINET 90x48 cm",
    "WOOD FOR ELECTRONICS RFID MAP 2pcs 39x31",
];
