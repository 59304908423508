export const countries = {
    AUSTRALIA: "AUSTRALIA",
    AUSTRIA: "AUSTRIA",
    BELGIUM: "BELGIUM",
    CANADA: "CANADA",
    DENMARK: "DENMARK",
    FRANCE: "FRANCE",
    GERMANY: "GERMANY",
    GREECE: "GREECE",
    ITALY: "ITALY",
    KOSOVO: "KOSOVO",
    KUWAIT: "KUWAIT",
    NETHERLANDS: "NETHERLANDS",
    NEW_ZEALAND: "NEW ZEALAND",
    POLAND: "POLAND",
    PORTUGAL: "PORTUGAL",
    ROMANIA: "ROMANIA",
    SAUDI_ARABIA: "SAUDI ARABIA",
    SLOVENIA: "SLOVENIA",
    SPAIN: "SPAIN",
    SWEDEN: "SWEDEN",
    SWITZERLAND: "SWITZERLAND",
    UAE: "UAE",
    UK: "UK",
    USA: "USA",
    IVORY_COAST: "IVORY COAST",
};

export const roomNames = {
    ALADDIN: "ALADDIN",
    ALICE_IN_WONDERLAND: "ALICE IN WONDERLAND",
    ALICE: "ALICE",
    ARKHAM_PRISON: "ARKHAM PRISON",
    ASYLUM: "ASYLUM",
    ATLANTIS: "ATLANTIS",
    AZTEC_EMPIRE_TEMPLE_OF_THE_SKULL: "AZTEC EMPIRE: TEMPLE OF THE SKULL",
    CHERNOBYL: "CHERNOBYL",
    CHOCOLATE_FACTORY: "CHOCOLATE FACTORY",
    CHOCOLATE_FACTORY_2: "CHOCOLATE FACTORY 2",
    CIRCUS: "CIRCUS",
    DRACULA: "DRACULA",
    FORBIDDEN_TEMPLE_OF_MONTEZUMA: "FORBIDDEN TEMPLE OF MONTEZUMA",
    HAUNTED_MANSION: "HAUNTED MANSION",
    HORROR_CIRCUS: "HORROR CIRCUS",
    HUNTERS_LODGE: "HUNTER'S LODGE",
    ILLUMINATI: "ILLUMINATI",
    JACK_THE_RIPPER: "JACK THE RIPPER",
    LA_CASA: "LA CASA",
    LA_MISSION_DEL_PROFESSOR: "LA MISSION DEL PROFESSOR",
    LOST_CITY_OF_ATLANTIS: "LOST CITY OF ATLANTIS",
    MANIAC_I_WANT_TO_PLAY_A_GAME: "MANIAC: I WANT TO PLAY A GAME",
    MINOTAUR: "MINOTAUR",
    MINOTAURS_LABYRINTH: "MINOTAUR'S LABYRINTH",
    MONEY_HEIST: "MONEY HEIST",
    MONTEZUMA: "MONTEZUMA",
    NECROMANCY: "NECROMANCY",
    NIGHTMARE_HOTEL: "NIGHTMARE HOTEL",
    ONE_THOUSAND_AND_ONE_NIGHTS_ALF_LAYLA_WA_LAYLAH:
        "ONE THOUSAND AND ONE NIGHTS ALF LAYLA WA LAYLAH",
    ONE_THOUSAND_AND_ONE_NIGHTS: "ONE THOUSAND & ONE NIGHTS",
    ORDER_OF_ASSASSINS: "ORDER OF ASSASSINS",
    ORFANOTROFIO: "ORFANOTROFIO",
    ORIENT_EXPRESS_THE_MURDER: "ORIENT EXPRESS THE MURDER",
    ORIENT_EXPRESS: "ORIENT EXPRESS",
    ORPHANAGE: "ORPHANAGE",
    PHANTAZMA_MEDIEVAL_CASTLE: "PHANTAZMA / MEDIEVAL CASTLE",
    PHILOSOPHERS_STONE_2: "PHILOSOPHERS STONE 2",
    PHILOSOPHERS_STONE: "PHILOSOPHER'S STONE",
    PHILOSOPHERS_STONE_4_SECRET_KEEPERS: "PHILOSOPHER'S STONE 4 SECRET KEEPERS",
    PIRATES_OF_THE_CARIBBEAN: "PIRATES OF CARIBBEAN",
    PIRATES_QUEEN_ANNES_REVENGE: "PIRATES: QUEEN ANNE'S REVENGE",
    PIRATES: "PIRATES",
    PRISONERS_OF_WAR: "PRISONERS OF WAR",
    REACTOR: "REACTOR",
    SANITARIUM: "SANITARIUM",
    SOM2: "SOM2",
    SPACE_WARS: "SPACE WARS",
    SQUID_GAME: "SQUID GAME",
    TEMPLE_OF_THE_SKULL: "TEMPLE OF THE SKULL",
    THE_ORDER_OF_ASSASSINS: "THE ORDER OF ASSASSINS",
    TOP_SECRET: "TOP SECRET",
    TOP_SECRET_2: "TOP SECRET 2",
    TOYMAKER: "TOYMAKER",
    TUTANKHAMUNS_TOMB: "TUTANKHAMUN'S TOMB",
    TWENTY_THOUSAND_LEAGUES: "TWENTY THOUSAND LEAGUES",
    WALKING_DEATH: "WALKING DEATH",
    WHITE_HOUSE: "WHITE HOUSE",
    WINTER_IS_COMING: "WINTER IS COMING",
};
