export const atlantisItems = [
    "Triton's key column",
    "Column 2",
    "Column 3",
    "Column 4",
    "Waterfall",
    "Hexagon 1st room",
    "Hexagon 3rd room (Trident lock)",
    "Holley Molly",
    "AHS",
    "Wooden box Poseidon",
    "Wooden box arrows touch",
    "RFID plates",
    "RFID shelves",
    "Fish LDR",
    "Fish LED",
    "Installation and testing of blue LED strips 1m in the waterfall",
    "LED strips in shells x4",
    "Sea lights x12",
    "E27 socket with 30cm cable for corals x2",
    "Passage EL/ET 1x1",
    "Electronics Main 33.5x53",
    "Power supplies 28x38",
    "Electronics AHS 40x50",
    "Hexagon covers & electronics",
    "Passage gate 1x1",
];
