// Utilities
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

// Components
import Completed from "Views/Completed/Completed";
import NavBar from "Components/NavBar/NavBar";
import Pending from "Views/Pending/Pending";
import { login, logout } from "utils/JWTAuth";
import TicketForm from "Views/TicketForm/TicketForm";
import "Components/Shared/Checkboxes/checkBoxes.css";
import { NotFound } from "Components/404";

// Styles
import "./App.css";
import "Components/TicketTemplate/style.css";
import Reporting from "Views/Reporting";
import CreateReport from "Views/Reporting/Create";
import ViewReport from "Views/Reporting/[id]";

const App = () => {
    const [ticketCounter, setTicketCounter] = useState(0);
    const [loginProfile, setLoginProfile] = useState("");
    const [isAuth, setIsAuth] = useState(
        JSON.parse(localStorage.getItem("iA")) || false
    );
    const [ticketData, setTicketData] = useState({
        status: "pending",
        isDisabled: true,
        isSolved: false,
        ismounted: false,
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [causes, setCauses] = useState([]);

    const handleLogin = (data) => {
        async function getLoginResult() {
            const response = await login(data);
            handleLoginResponse(response);
        }

        getLoginResult();

        const handleLoginResponse = (response) => {
            if (response.message === "Successful login.") {
                setLoginProfile(response.username);
                setIsAuth(true);
                localStorage.setItem("iA", "true");
                localStorage.setItem("access_token", response.jwt);
                localStorage.setItem("userDetails", JSON.stringify(response));
            } else {
                setErrorMessage("Login failed!");
            }
        };
    };

    const handleLogout = () => {
        logout();

        setLoginProfile(null);
        setIsAuth(false);
    };

    const handleTicketCounter = (ticketCounter) => {
        setTicketCounter(ticketCounter);
    };

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            try {
                const decoded = jwtDecode(token);
                setLoginProfile(decoded.data.username);
                setIsAuth(true);
            } catch (error) {
                console.error("Token decoding failed:", error);
                setIsAuth(false);
                localStorage.removeItem("access_token");
            }
        }
    }, []);
    return (
        <BrowserRouter>
            <NavBar
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                loginProfile={loginProfile}
                pendingsStatus={ticketCounter}
            />
            {isAuth && (
                <Routes>
                    <Route
                        exact
                        path='/'
                        replace
                        element={
                            <Pending
                                isAuth={isAuth}
                                loginProfile={loginProfile}
                                setCauses={setCauses}
                                setTicketCounter={setTicketCounter}
                                profile={loginProfile}
                                handleTicketCounter={handleTicketCounter}
                                pendingsStatus={ticketCounter}
                                setTicketData={setTicketData}
                                ticketData={ticketData}
                            />
                        }
                    />
                    <Route
                        path='/form'
                        replace
                        element={
                            <TicketForm
                                causes={causes}
                                isInCompleted={false}
                                isInPending={false}
                            />
                        }
                    />
                    <Route
                        path='/completed'
                        element={
                            <Completed
                                setTicketData={setTicketData}
                                ticketData={ticketData}
                            />
                        }
                    />
                    <Route
                        path='/reporting'
                        element={<Reporting />}
                    />
                    <Route
                        path='/reporting/create'
                        element={<CreateReport />}
                    />{" "}
                    <Route
                        path='/reporting/:id'
                        element={<ViewReport />}
                    />{" "}
                    <Route
                        path='*'
                        element={<NotFound link={"/"} />}
                    />
                </Routes>
            )}
            {/**
    Authenticates the user.
     */}
            {errorMessage === "Login failed!" && (
                <>
                    <div
                        className='loginPopupContainer'
                        style={{
                            border: "4px solid #da1a35",
                            borderRadius: "1em",
                            background: "#0a0a0a",
                        }}>
                        Wrong username or password!
                    </div>
                </>
            )}
        </BrowserRouter>
    );
};

export default App;
