export const orientExpressItems = [
    'ADMIRAL MONITOR (FILM REMOVAL) 22"',
    "AHS CABINET",
    "AHS MONITOR FRAME",
    "BAR",
    "BAR PASSAGE - RFID BOTTLES",
    "JUKEBOX",
    "JACK & FRANKSWITCH PANEL (JACK STICKING)",
    "LETTER BOX",
    '2x TV MONITORS 50" & DISABLE SLEEP TIMER, ECO MODE',
    'TV MONITOR 32" & DISABLE SLEEP TIMER',
    "SECRETAIRE - INSPECTOR",
    "SECRETAIRE - 2nd CABIN",
    "TRAIN - CITIES - DATES",
    "LDRs ADMIRAL",
    "LED GEARS",
    "WOODEN BOX M/C",
    "POLARIZED LENS",
    "LUGGAGE M/C",
    "BED PASSAGE",
    "EXIT LED ON WOODEN BASE",
    "STETHOSCOPE",
    "ADMIRAL MONITOR DOOR",
    "RADIO SPEAKER",
    "ELECTRONICS BAR PASSAGE: 24X80",
    "ELECTRONICS BAR 45X36",
    "ELECTRONICS BAR 33X28 SOS 2 PCS",
    "ELECTRONICS SECRETAIRE 73X35 2 PCS",
];
