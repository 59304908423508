export const illuminatiItems = [
    "BOOKCASE PASSAGE",
    "OFFICE DESK",
    "UV LIGHT",
    "CHESSBOARD",
    "CONSOLE",
    'PC SETUP FOR CONSOLE WITH 24" MONITOR',
    "KNIGHT",
    "RISING COLUMN",
    "MAP",
    "SHIELD",
    "BUDDHA CONSTRUCTION",
    "BUDDHA",
    "BOOKS MASON",
    "STONES CONSTRUCTION",
    "HOLY ARC",
    "ELECTRICAL PANEL",
    'PYRAMID WITH 24" MONITOR',
    "HAND RIGHT",
    "HAND LEFT",
    "HARD DISK x2",
    "USB RADIO x2",
    "USB OFFICE x2",
    "RADIO",
    "SAFEBOX",
    "SAFE DOOR",
    "ELECTRONICS ILLUMINATI BUDDHA 90x34",
    "ELECTRONICS ILLUMINATI MAP 33x55",
    "ELECTRONICS ILLUMINATI BOOKCASE 45x29",
    "ELECTRONICS KNIGHT 68x24",
    "POWER SUPPLY OFFICE 37x52",
];
