export const twdItems = [
    "CONSOLE - MAIN ELECTRONICS",
    "CONSOLE - DOOR M/C",
    "TOILET FLUSH - IR & EL/ET",
    "CABINET ZOMBIE PNEUMATIC",
    "WEAPONS HOLDER M/C",
    "CASH REG DISPLAYS",
    "GUN",
    "LED SHADES",
    "BARREL JUMPSCARE PNEUMATIC",
    "WC JUMPSCARE PNEUMATIC",
    "BUMPING DOOR PNEUMATIC",
    "2x METAL DOORS M/C",
    "METAL DOOR HANDLE SWITCH - (METAL STRIP WITH SWITCH)",
    'SCREEN PREPARATION WITH BASES - DISABLE ECO & SLEEP FUNCTION (2 MONITORS 24")',
    "3 BOXES FLASHER & PCB",
    "PLEXI DOOR M/C",
    "BOX OF MIDDLE CELL WITH RJ45",
    "BOX WITH CABLE AND MALE SOCKET",
    "KEY HOLDER M/C WITH RJ45 - 10x10 BOX",
    "ELECTRONICS TWD CONSOLE 140x55",
];
