export const ProvideMoreInfo = "Provide More Information";

export const departmentNames = ["electronic", "other"];

export const MockedReports = [
    {
        report_id: "xxxxxxxxx",
        report_name: "xxxxxxxxx",
        installation: "xxxxxxxxx",
        checklist: [{ component: "", status: "" }],
        status: "xxxxxxxxx",
        room_name: "xxxxxxxxx",
        reporter: "xxxxxxxxx",
        reporter_id: "xxxxxxxxx",
        created_at: Date.now(),
    },
];

const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

export const MockedReport = {
    label: "",
    installation: "",
    checklist: [{ component: "", status: "" }],
    status: "",
    room_name: "",
    reporter: "",
    reporter_id: JSON.parse(window.localStorage.getItem("userDetails"))?.userId,
    created_at: `${day}-${month}-${year}`,
};
