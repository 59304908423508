export const chocolateItems = [
    "AHS",
    "BOX WITH CHRISTMAS LIGHTS",
    "WOOD WITH ELECTRONICS OF CENTRAL BOX",
    "SCREWS OF DIFFERENT LENGTH",
    "M/C SHAPES",
    "CORKS",
    "STROBO WITH BASE",
    "RGB LED TAPE ON CENTRAL COLUMN",
    "BOX WITH CANS",
    "EMBEDDED BOXES (OVEN SWITCHES, LOCK, LIGHTING)",
    "CUPBOARD 1 WITH KEYPAD",
    "CUPBOARD 2 WITH LED TAPE",
    "SOLENOID RINGS",
    "HELP MECHANISM",
    "STROBO",
];
