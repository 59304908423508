import { ProvideMoreInfo } from "constants/constants";
import {
    sharedProperties,
    sharedUiSchema,
    submitButtonConfig,
} from "schemas/sharedSchema";

export function generateSchema(items, excludeKey = null) {
    return {
        type: "object",
        properties: {
            ...items
                ?.filter((item) => item !== excludeKey)
                .reduce((acc, item) => {
                    acc[item] = { type: "string" };
                    return acc;
                }, {}),
            ...Object.fromEntries(
                Object.entries(sharedProperties).filter(
                    ([key]) => key !== excludeKey
                )
            ),
        },
    };
}

export function generateUiSchema(items, excludeKey = null) {
    return {
        ...items
            ?.filter((item) => item !== excludeKey)
            .reduce((acc, item) => {
                acc[item] = {
                    "ui:placeholder": ProvideMoreInfo,
                    "ui:widget": "textarea",
                };
                return acc;
            }, {}),
        ...Object.fromEntries(
            Object.entries(sharedUiSchema).filter(([key]) => key !== excludeKey)
        ),
        ...submitButtonConfig,
    };
}
