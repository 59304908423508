export const orphanageItems = [
    "DESK MAIN ELECTRONICS",
    "ANIMAL BOARD",
    "BOOK M/C",
    "CLOSET PASSAGE",
    "KEYPAD EXIT",
    "KEYPAD HORIZONTAL",
    "MEDICINE GAME",
    "MEMORIAL BOARD",
    "AHS MONITOR FRAME",
    "AHS CUPBOARD",
    "FRANK SWITCH 1ST ROOM",
    "TORMENTUM BOARD",
    "X-RAY PANEL",
    "SHELF",
    "WHEEL M/C",
    "FRANK SWITCH",
    "CANDLES x4",
    "RJ45 TO CONNECTORS IN THE LIGHT OF THE 2ND ROOM",
    "METAL CLOSET WITH JUMPSCARE",
    "ELECTRONICS MAIN 80x60",
];
