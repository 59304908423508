export const chernobylItems = [
    "AHS CABINET",
    "AHS MONITOR METAL FRAME",
    "CONSOLE 1ST ROOM",
    "CONSOLE 2ND ROOM",
    "GEARS",
    "SCREWS PANEL",
    "METAL PANEL",
    "ELECTRICAL BOARD",
    "CABINET PASSAGE M/C",
    "TELEPHONE",
    "LAMPS SHAPES",
    "METAL LOCKER M/C",
    "SAFE BOX M/C",
    "CABINETS M/C x2",
    "TOOLBOX M/C",
    "ELECTRONICS CONSOLE 1st ROOM",
    "ELECTRONICS CONSOLE 2nd ROOM",
];
