export const maniacItems = [
    "CONSOLE WITH ELECTRONICS",
    "NIGHTSTAND 1ST ROOM",
    "NIGHTSTAND 2ND ROOM",
    "FINGERPRINT SCANNER",
    "CEILING BOX",
    "ELECTRICAL BOARD",
    "(2x UV LED STRIPS ON WOOD) x2",
    "SLIDEBOX",
    "TAPE RECORDER",
    "AHS SCREEN",
    "SAFE BOX",
];
