import ViewImg from "assets/eyeView.svg";
import Green_Eye from "assets/green_eye.svg";
import PropTypes from "prop-types";

const GenerateTicketTable = ({ item, viewTicket }) => {
    const viewHandler = (item) => {
        viewTicket(item);
    };
    const today = Date.now();
    const daysDiff = Math.floor(
        (today - Date.parse(item.completed_date)) / 86400 / 1000
    );

    const { id, store, country, room, assistant, completed_date } = item;
    return (
        <tr>
            {daysDiff > 2 ? (
                <>
                    <td>{id}</td>
                    <td>{store}</td>
                    <td>{country}</td>
                    <td>{room}</td>
                    <td> {assistant}</td>
                    <td> {completed_date}</td>
                    <td>
                        <img
                            style={{ color: "green" }}
                            width='40'
                            src={ViewImg}
                            alt='View Icon'
                            onClick={() => viewHandler(item)}
                        />
                    </td>
                </>
            ) : (
                <>
                    <td style={{ color: "green" }}>{id}</td>
                    <td style={{ color: "green" }}>{store}</td>
                    <td style={{ color: "green" }}>{country}</td>
                    <td style={{ color: "green" }}>{room}</td>
                    <td style={{ color: "green" }}>{assistant}</td>
                    <td style={{ color: "green" }}>{completed_date}</td>
                    <td>
                        <img
                            style={{ fill: "green" }}
                            width='40'
                            src={Green_Eye}
                            alt='View Icon'
                            onClick={() => viewHandler(item)}
                        />
                    </td>
                </>
            )}
        </tr>
    );
};

GenerateTicketTable.propTypes = {
    item: PropTypes.object.isRequired,
    viewTicket: PropTypes.func.isRequired,
};

export default GenerateTicketTable;
