export const jackTheRipperItems = [
    "AHS MONITOR",
    "CLOSET WITH ELECTRONICS",
    "BALL BOX",
    "CLOCK",
    "WINDOW EL/ET",
    "WOOD WITH M/C BRICKS x3",
    "BIN EL/ET or BOOTH PHONE",
    "PHONE BOOTH EL/ET",
    "WHEELBARROW",
    "CHAIR",
    "DOUBLE CABINET",
    "SINGLE CUPBOARD WITH ARROW TOUCHPAD AND CLOCK",
    "NEWSPAPER MACHINE",
    "TABLE DRAWER M/C",
    "FOG MACHINE",
    "ELECTRONICS CLOSET 37x44",
    "ELECTRONICS KNOCK 37x44",
];
