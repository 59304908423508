export const templeOfSkullItems = [
    "CHESSBOARD",
    "GATE",
    "GATE BASE",
    "SCORE4",
    "TOTEM BALL PROP",
    "STICK COLUMN (CHICKEN LEG)",
    "TANGRAM",
    "RISING COLUMN - SPOTLIGHT (250mm motor)",
    "RISING COLUMN (200mm motor) - ABOLISH LEVITRON - USE AED TAPE OR LED INSTEAD",
    "AHS MONITOR FRAME",
    "AHS CABINET",
    "BRICK - EL/ET",
    "BRICK - SWITCH",
    "MASK M/C",
    "MASK MAGNET",
    "SKULLS LED STRIPS 20pcs",
    "LASERS",
    "UV LED",
    "MIRRORS MAGNET",
    "WOOD WITH ENGRAVING FOR MIRRORS",
    "HEXAGON WITH SPOT, MAGNET AND WIRELESS CHARGING",
    "BASE FOR HEXAGON WITH SPOT WITH MAGNETIC CONTACT AND WIRELESS CHARGING",
    "PARROT BASE M/C",
    "ENGRAVINGS ON A COLUMN AT THE ANGEL",
    "WOOD WITH 2 SMALL SPOTLIGHTS x2",
    "DOG BOX",
    "CHEST M/C",
    "LED TAPES WARM WHITE 20CM IN CHANNEL FOR COLUMNS WITH MIRRORS 2 PIECES",
    "2x FOG MACHINES (WITH SHIPPING LABEL ON THE BOX)",
    "ELECTRONICS DOGBOX 36X45 & 50X20",
    "ELECTRONICS GATE 115X20",
    "ELECTRONICS CHESSBOARD 40X40 & 35X35",
];
