import GenerateCountryList from "utils/generateCountryList";
import GenerateStoreList from "utils/generateStoreList";
import GenerateRoomList from "utils/generateRoomList";
import DropDown from "Components/Dropdown/DropDown";
import CheckBoxes from "Components/Shared/Checkboxes/checkBoxes";
import { departmentNames } from "constants/constants";
import PropTypes from "prop-types";

const InfoTemplate = ({ formState, handleFormChange, isInCompleted }) => {
    const handleTextAreaChange = (e) => {
        const { name, value } = e.target;
        handleFormChange({
            target: { name, value },
        });
    };

    const handleDepartmentType = (e) => {
        const { name, checked } = e.target;
        handleFormChange({
            target: { name: "department", value: checked ? name : "" },
        });
    };

    return (
        <div className='formItem'>
            <DropDown
                name='country'
                defaultValue={formState.country}
                isInCompleted={isInCompleted}
                handleChange={handleFormChange}>
                <GenerateCountryList />
            </DropDown>
            <DropDown
                name='store'
                isInCompleted={isInCompleted}
                defaultValue={formState.store}
                handleChange={handleFormChange}>
                <GenerateStoreList selectedCountry={formState?.country} />
            </DropDown>
            <DropDown
                name='room'
                isInCompleted={isInCompleted}
                defaultValue={formState.room}
                handleChange={handleFormChange}>
                {GenerateRoomList(formState?.store)}
            </DropDown>
            <div className='form_input'>
                <label className='label'>Assignee</label>
                <textarea
                    name='assistant'
                    disabled={isInCompleted}
                    value={formState.assistant}
                    onChange={handleTextAreaChange}></textarea>
            </div>
            <div className='form_input'>
                <label className='label'>Reporter</label>
                <textarea
                    name='reporter'
                    disabled={isInCompleted}
                    value={formState.reporter}
                    onChange={handleTextAreaChange}></textarea>
            </div>
            <div className='form_input'>
                <label className='label'>Department</label>
                {departmentNames.map((dept) => (
                    <CheckBoxes
                        key={dept}
                        name={dept}
                        handleDepartmentType={handleDepartmentType}
                        checked={formState.department}
                        isDisabled={isInCompleted}
                    />
                ))}
            </div>
        </div>
    );
};

InfoTemplate.propTypes = {
    formState: PropTypes.object,
    handleFormChange: PropTypes.func,
    isInCompleted: PropTypes.bool,
};

export default InfoTemplate;
