export const aladdinItems = [
    "Magic Carpet",
    'Genie Mirror 49" \'H 50" TV',
    "Market Table with EL/ET comp",
    "Market Stand Cupboards",
    "9 Cupboards - Drawers",
    "Genie Cupboard & Speaker for carpet sfx with amp&df player",
    "Exit lock",
    "Animals Stand",
    "AHS Door",
    "Stairs",
    "Rising Column",
    "3x Chests EL/ET",
    "4x Chests Knocks",
    "Vases & chests",
    "Hamsa Hands",
    "Wooden Box Chest M/C",
    "DOOR PASSAGE",
    "ELECTRONICS - MARKET STAND 70X45",
    "ELECTRONICS - CHESTS 110X37",
    "ELECTRONICS - STAIRS 79X45",
    "ELECTRONICS - SMALL CUPBOARDS 85X30",
    "ELECTRONICS - CARPET 33X27",
    "ELECTRONICS - GENIE",
    "8x LED Tapes 0.5m in channel",
];
