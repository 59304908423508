export const minotaurItems = [
    "SWORDS CONSTRUCTION - MAIN ELECTRONICS",
    "EMERGING",
    "CHEST",
    "GEARS",
    "HEROES SHIELDS",
    "L=V M/C",
    "CEILING MAZE",
    "METAL BUTTONS CARDS",
    "SWORDS",
    "AHS CUPBOARD",
    "AHS MONITOR FRAME",
    "SUNDIAL- ΗΛΙΑΚΟ ΡΟΛΟΙ",
    "SWORDS HOLDER",
    "ROTATED STRUCTURE",
    "PASSAGE DOOR",
    "ELECTRONICS - MAIN 97X80",
];
