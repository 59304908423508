export const nightmareHotelItems = [
    "AHS CABINET",
    "AHS FRAME MONITOR",
    "CALENDAR",
    "ELEVATOR",
    "2x ELEVATOR DOOR EL/ET",
    "ELEVATOR LED WINDOW",
    "KEYPAD - RECEPTION & CALL BUTTON",
    "CALL BUTTON - CORRIDOR",
    'QUIJA MIRROR 50" TV',
    "QUIJA MECHANISM",
    "QUIJA TABLE",
    "RECEPTION",
    "TROLLEY",
    "VANISHING ROOM",
    "VANISHING ROOM - SHELF WITH 2 CANDLES",
    "RADIO",
    "SHELF & CANDLES - RADIO",
    "SHELF SPK & CANDLE",
    "SHELF EL/ET",
    "VANISHING DOOR EL/ET",
    "RECEPTION PASSAGE",
    "2 BELL PROX",
    "NIGHT STAND",
    "5x PENTAGRAM CANDLES",
    "PENTAGRAM LUGGAGE & M/C",
    "FOOD ELEVATOR",
    "FOOD ELEVATOR SWITCHES PANEL",
    "4x FRAMES WITH EYES",
    "SINS PANEL",
    "FLOORS INDICATOR",
    "OPEN INDICATOR",
    "UP/DOWN PANEL",
    "FRONT OF CNC JUKEBOX- (BTNS WITH RJ45)",
    'TV IN BEDROOM 24" MONITOR',
    "RJ45 CIRCUIT BOARD WITH CABLE & AUTOMATIC CLAMP FOR CORRIDOR LIGHTS",
    "MIRROR EGG BASE - LED STRIP - LAMP",
    "ECO DISABLING & TIMER SLEEP ON QUIJA TV",
    "UV LIGHT",
    "2 FIREPLACE CANDLES (CANDLE HOLDER)",
    "VANISHING ROOM WIRING",
    "EXIT LED STRIP IN WOODEN CHANNEL",
    "FRAME MECHANISMS 27x4(8cm) x4",
    "ELECTRONICS ELEVATOR 35.5x125",
    "ELECTRONICS QUIJA 90x43",
    "ELECTRONICS RECEPTION 64x100",
    "ELECTRONICS SUITCASE 45x39",
    "ELECTRONICS FOOD ELEVATOR 39x75",
    "ELECTRONICS VANISHING ROOM 44x42",
    "ELECTRONICS WOOD TV 25x50",
];
