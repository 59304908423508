export const whiteHouseItems = [
    "LIBRARY BOOKCASE",
    "WAR TABLE",
    "CONSOLE",
    "4x PHONES",
    "SUITCASE",
    "AHS MONITOR FRAME",
    "JAPAN FLAG",
    "OFFICE DESK",
    "4x PRESIDENTS",
    "ELECTRONICS CONSOLE 150x30",
    "PRESIDENTS M/C FOR CNC 87.5 x 25 cm",
    'FURNITURE WITH MOVABLE 32" SCREEN',
];
