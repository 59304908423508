/* eslint-disable react/prop-types */
export const NotFound = ({ link }) => {
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                width: "100%",
                height: "100vh",
            }}>
            <a
                href={link}
                style={{
                    padding: "2%",
                    position: "fixed",
                }}>
                ← Go Back
            </a>
            <div
                style={{
                    textAlign: "center",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "black",
                    alignItems: "center",
                }}>
                <h1
                    style={{
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        verticalAlign: "top",
                        margin: "0 20px 0 0",
                        borderRight: "1px solid rgba(255, 255, 255, 0.3)",
                        padding: "0 23px 0 0",
                    }}>
                    404
                </h1>
                <h2
                    style={{
                        fontSize: "1rem",
                        fontWeight: "400",
                    }}>
                    This page could not be found.
                </h2>
            </div>
        </div>
    );
};
